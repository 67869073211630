import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Container, Row } from "reactstrap";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import _ from "lodash";
import { useHistory } from "react-router-dom";

import Breadcrumb from "../../../layout/breadcrumb";
import Paginator from "../../../components/list/Paginator";
import Search from "../../../components/list/Search";
import AuditorTable from "./table/AuditorTable";
import AuditorForm from "./form/AuditorForm";
import Fetching from "../../../components/fetching/Fetching";
import Confirm from "../../../components/confirm/Confirm";

import api from "../../../services/api";

const Auditor = (props) => {
	let history = useHistory();

	const pageName = "Auditors";
	const [isFetching, setIsFetching] = useState(true);
	const [search, setSearch] = useState("");
	const [mainSearch, setMainSearch] = useState("");
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [permissions, setPermissions] = useState([]);
	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");
	const [total, setTotal] = useState("");
	const [lastPage, setLastPage] = useState("");

	const [selected, setSelected] = useState("");
	const [modal, setModal] = useState(false);

	const [users, setUsers] = useState([]);

	useEffect(() => {
		const form = {
			page,
			search,
		};

		api.get("/client/auditors", form).then((response) => {
			updateData(response);
		});
		// eslint-disable-next-line
	}, [page, search]);

	const updateData = (response) => {
		const { auditors, permissions, users } = response;

		setPermissions(permissions);
		setData(auditors.data);
		setFrom(auditors.from);
		setTo(auditors.to);
		setTotal(auditors.total);
		setLastPage(auditors.last_page);

		setUsers(users);

		setIsFetching(false);
	};

	const delayedQuery = useCallback(
		_.debounce((v) => {
			setSearch(v);
		}, 500),
		[]
	);

	const setDelaySearch = (value) => {
		setMainSearch(value);
		delayedQuery(value);
	};

	// Form
	const createElement = () => {
		history.push("/auditors/create");
	};

	const editElement = (item) => {
		history.push("/auditors/" + item.slug);
	};

	const handleEdit = (data) => {
		return api
			.post("/client/auditors_save", data)
			.then((response) => {
				updateData(response);
				toast.success(props.t("Record saved successfully"));
				history.push("/auditors");

				return true;
			})
			.catch((error) => {
				return false;
			});
	};

	const deleteElement = (item) => {
		setSelected(item.id);
		setModal(true);
	};

	const handleDelete = () => {
		const form = {
			selected,
		};

		api.post("/client/auditors_delete", form).then((response) => {
			updateData(response);
			setSelected("");

			toast.success(props.t("Record deleted successfully"));
		});
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<Fragment>
			<Breadcrumb title={pageName} parent="Maintainers" />
			<Container fluid={true}>
				<Row>
					<Search
						search={mainSearch}
						setSearch={setDelaySearch}
						to={to}
						from={from}
						total={total}
						permissions={permissions}
						handleCreate={createElement}
					/>

					<AuditorTable
						editElement={editElement}
						deleteElement={deleteElement}
						data={data}
						permissions={permissions}
					/>

					<Paginator
						page={page}
						setPage={setPage}
						lastPage={lastPage}
					/>
				</Row>
			</Container>

			<AuditorForm
				pageName={pageName}
				users={users}
				callbackAction={handleEdit}
			/>

			<Confirm
				modal={modal}
				setModal={setModal}
				callbackAction={handleDelete}
			/>
		</Fragment>
	);
};

export default translate(Auditor);
