import React from "react";
import Select from "react-select";
import { Col, Label, Button } from "reactstrap";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { translate } from "react-switch-lang";
import api from "services/api";
import { getNow } from "services/utils";

const Fields = ({ data, setData, users, userID, setIsFetching }) => {
	const changeFeedbackEfficacy = (editor) => {
		const newData = { ...data, feedback_efficacy: editor.getData() };
		setData(newData);
	};

	const validCloser = () => {
		if (!data.closer_id) {
			toast.error("Debe seleccionar un usuario responsable de la verificación e implementación.");
			return false;
		}
		if (Number(data.closer_id) !== Number(userID)) {
			toast.error("El usuario responsable de la verificación e implementación debe ser el usuario logueado.");
			return false;
		}
		if (data.corrective_actions_details.length === 0) {
			toast.error("Debe agregar al menos un detalle de la acción correctiva.");
			return false;
		}

		return true;
	};

	const validImplementer = () => {
		if (!data.implementer_id) {
			toast.error("Debe seleccionar un usuario responsable del cierre.");
			return false;
		}
		if (Number(data.implementer_id) !== Number(userID)) {
			toast.error("El usuario responsable del cierre debe ser el usuario logueado.");
			return false;
		}
		if (!data.is_effective) {
			toast.error("Debe seleccionar si el plan de acción fue eficaz.");
			return false;
		}

		return true;
	};

	const handleConfirmCloser = () => {
		if (!validCloser()) return;

		setIsFetching(true);
		api.post("/client/corrective_actions/confirm_closer", { ...data })
			.then((response) => {
				setIsFetching(false);
				toast.success("Se ha confirmado la verificación e implementación");
				setData({ ...data, closer_date: getNow(), closer_sign: true });
			})
			.catch((e) => {
				setIsFetching(false);
			});
	};

	const handleConfirmImplementer = () => {
		if (!validImplementer()) return;

		setIsFetching(true);
		api.post("/client/corrective_actions/confirm_implementer", { ...data })
			.then((response) => {
				setIsFetching(false);
				toast.success("Se ha confirmado el cierre de la acción correctiva");
				setData({ ...data, implementer_date: getNow(), implementer_sign: true });
			})
			.catch((e) => {
				setIsFetching(false);
			});
	};

	return (
		<div className="form-tab-detail">
			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="affected_area">Usuario responsable de la verificación e implementación</Label>
					<Select
						defaultValue={data.closer_id ? users.filter((c) => Number(c.value) === Number(data.closer_id)) : ""}
						placeholder={<div>Seleccione...</div>}
						options={users}
						onChange={(value) => {
							setData({
								...data,
								closer_id: value.value,
							});
						}}
					/>
				</Col>
				<Col md="6 mb-3">
					<Label htmlFor="affected_area">Confirmación responsable de la verificación e implementación</Label>
					<div>
						{!data.closer_sign ? (
							Number(data.closer_id) === Number(userID) && (
								<Button color="primary" onClick={() => handleConfirmCloser()}>
									Confirmar
								</Button>
							)
						) : (
							<Button color="success" disabled>
								Confirmado | {data.closer_date}
							</Button>
						)}
					</div>
				</Col>
				<Col md="6 mb-3">
					<Label htmlFor="affected_area">El Plan de Acción fue Eficaz?</Label>
					<Select
						defaultValue={
							data.is_effective
								? [
										{ value: "Si", label: "Si" },
										{ value: "No", label: "No" },
								  ].filter((c) => String(c.value) === String(data.is_effective))
								: ""
						}
						placeholder={<div>Seleccione...</div>}
						options={[
							{ value: "Si", label: "Si" },
							{ value: "No", label: "No" },
						]}
						onChange={(value) => {
							setData({
								...data,
								is_effective: value.value,
							});
						}}
						isDisabled={data.implementer_sign ? true : false}
					/>
				</Col>
				<Col md="12 mb-3">
					<Label htmlFor="audit_schedule_id">Comentarios sobre la eficacia de las Acciones</Label>
					<CKEditor
						editor={ClassicEditor}
						data={data && data.feedback_efficacy ? data.feedback_efficacy : ""}
						onChange={(event, editor) => {
							changeFeedbackEfficacy(editor);
						}}
					/>
				</Col>
				<Col md="6 mb-3">
					<Label htmlFor="affected_area">Usuario responsable de cierre</Label>
					<Select
						defaultValue={data.implementer_id ? users.filter((c) => Number(c.value) === Number(data.implementer_id)) : ""}
						placeholder={<div>Seleccione...</div>}
						options={users}
						onChange={(value) => {
							setData({
								...data,
								implementer_id: value.value,
							});
						}}
					/>
				</Col>
				<Col md="6 mb-3">
					<Label htmlFor="affected_area">Confirmación responsable de cierre</Label>
					<div>
						{!data.implementer_sign ? (
							Number(data.implementer_id) === Number(userID) && (
								<Button color="primary" onClick={() => handleConfirmImplementer()}>
									Confirmar
								</Button>
							)
						) : (
							<Button color="success" disabled>
								Confirmado | {data.implementer_date}
							</Button>
						)}
					</div>
				</Col>
			</div>
		</div>
	);
};

export default translate(Fields);
