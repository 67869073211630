import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Container, Row } from "reactstrap";
import { translate } from "react-switch-lang";
import _ from "lodash";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import Breadcrumb from "../../../layout/breadcrumb";
import Paginator from "../../../components/list/Paginator";
import Search from "../../../components/list/Search";
import StandardTable from "./table/StandardTable";
import StandardForm from "./form/StandardForm";
import Fetching from "../../../components/fetching/Fetching";
import Confirm from "../../../components/confirm/Confirm";

import api from "../../../services/api";

const Standard = (props) => {
	let history = useHistory();

	const pageName = "Standards";
	const [isFetching, setIsFetching] = useState(true);
	const [search, setSearch] = useState("");
	const [mainSearch, setMainSearch] = useState("");
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [permissions, setPermissions] = useState([]);
	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");
	const [total, setTotal] = useState("");
	const [lastPage, setLastPage] = useState("");

	const [selected, setSelected] = useState("");
	const [modal, setModal] = useState(false);

	const [globalStandards, setGlobalStandards] = useState([]);
	const [globalStandardsDetail, setGlobalStandardsDetail] = useState([]);
	const [questions, setQuestions] = useState([]);

	useEffect(() => {
		const form = {
			page,
			search,
		};

		api.get("/client/standards", form).then((response) => {
			updateData(response);
		});
		// eslint-disable-next-line
	}, [page, search]);

	const updateData = (response) => {
		const { standards, permissions, global_standards, global_standards_detail, base_questions } = response;

		setPermissions(permissions);
		setData(standards.data);
		setFrom(standards.from);
		setTo(standards.to);
		setTotal(standards.total);
		setLastPage(standards.last_page);

		setGlobalStandards(global_standards);
		setGlobalStandardsDetail(global_standards_detail);
		setQuestions(base_questions);

		setIsFetching(false);
	};

	const delayedQuery = useCallback(
		_.debounce((v) => {
			setSearch(v);
		}, 500),
		[]
	);

	const setDelaySearch = (value) => {
		setMainSearch(value);
		delayedQuery(value);
	};

	// Form
	const createElement = () => {
		history.push("/admin/standards/create");
	};

	const editElement = (item) => {
		history.push("/admin/standards/" + item.slug);
	};

	const handleEdit = (data) => {
		return api
			.post("/client/standards_save", data)
			.then((response) => {
				updateData(response);
				history.push("/admin/standards");

				toast.success(props.t("Record saved successfully"));

				return true;
			})
			.catch((error) => {
				return false;
			});
	};

	const deleteElement = (item) => {
		setSelected(item.id);
		setModal(true);
	};

	const handleDelete = () => {
		const form = {
			selected,
		};

		api.post("/client/standards_delete", form).then((response) => {
			updateData(response);
			setSelected("");

			toast.success(props.t("Record deleted successfully"));
		});
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<Fragment>
			<Breadcrumb title={pageName} parent="Maintainers" />
			<Container fluid={true}>
				<Row>
					<Search
						search={mainSearch}
						setSearch={setDelaySearch}
						to={to}
						from={from}
						total={total}
						permissions={permissions}
						handleCreate={createElement}
					/>

					<StandardTable editElement={editElement} deleteElement={deleteElement} data={data} permissions={permissions} />

					<Paginator page={page} setPage={setPage} lastPage={lastPage} />
				</Row>
			</Container>

			<StandardForm
				pageName={pageName}
				selected={selected}
				callbackAction={handleEdit}
				globalStandards={globalStandards}
				setGlobalStandards={setGlobalStandards}
				globalStandardsDetail={globalStandardsDetail}
				setGlobalStandardsDetail={setGlobalStandardsDetail}
				questions={questions}
				setQuestions={setQuestions}
			/>

			<Confirm modal={modal} setModal={setModal} callbackAction={handleDelete} />
		</Fragment>
	);
};

export default translate(Standard);
