import React, { useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Col,
	Label,
	Input,
} from "reactstrap";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";

import Fetching from "../../../../components/fetching/Fetching";

import api from "../../../../services/api";

const baseObject = {
	name: "",
};

const GlobalStandardForm = (props) => {
	const { pageName, t, callbackAction } = props;

	let history = useHistory();
	const { global_standard_id } = useParams();

	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState(baseObject);

	const handleClose = () => {
		history.push("/admin/global_standards");
	};

	const validSave = () => {
		if (!data.name || data.name === "") {
			toast.error(props.t("The name field is required"));
			return false;
		}

		return true;
	};

	const handleSave = async () => {
		if (validSave()) {
			setIsFetching(true);
			const response = await callbackAction(data);
			if (response) {
				setData(baseObject);
			}
			setIsFetching(false);
		}
	};

	useEffect(() => {
		setIsFetching(false);
		if (global_standard_id && global_standard_id !== "create") {
			setIsFetching(true);
			api.get("/admin/global_standard", {
				selected: global_standard_id,
			}).then((response) => {
				setData(response);
				setIsFetching(false);
			});
		} else {
			setData(baseObject);
		}
		// eslint-disable-next-line
	}, [global_standard_id]);

	return (
		<Modal
			isOpen={global_standard_id ? true : false}
			toggle={handleClose}
			size="lg"
			backdrop="static"
		>
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={handleClose}>
						{t(pageName)}
					</ModalHeader>
					<ModalBody>
						<div className="form-tab-detail">
							<div className="form-row">
								<Col md="6 mb-3">
									<Label htmlFor="name">{t("Name")}</Label>
									<Input
										className="form-control"
										name="name"
										type="text"
										value={data && data.name}
										onChange={(e) =>
											setData({
												...data,
												name: e.target.value,
											})
										}
									/>
								</Col>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={handleClose}>
							{t("Cancel")}
						</Button>
						<Button color="primary" onClick={() => handleSave()}>
							{t("Save")}
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default translate(GlobalStandardForm);
