import React from "react";
import { translate } from "react-switch-lang";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { ReactComponent as EditIcon } from "../../../../assets/icons/editar.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/basura.svg";
import { isValidPermission, dateToLocale } from "../../../../services/utils";

const UserTable = (props) => {
	const { editElement, deleteElement, data, permissions } = props;

	return (
		<div className="base-div">
			<Table className="base-table">
				<Thead>
					<Tr>
						<Th>Título</Th>
						<Th>Nombre Norma</Th>
						<Th>Detalle Norma</Th>
						<Th>Descripción</Th>
						<Th>Fecha Creación</Th>
						<Th>Usuario</Th>
						<Th>Acciones</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, i) => {
						return (
							<Tr key={i}>
								<Td>{item.title}</Td>
								<Td>{item.name}</Td>
								<Td>
									<ul>
										{item &&
											item.details &&
											item.details.map((detail, j) => {
												return (
													<li key={j}>
														{` ${j + 1}. ${
															detail.global_standards
														} `}
													</li>
												);
											})}
									</ul>
								</Td>
								<Td>
									<div
										dangerouslySetInnerHTML={{
											__html: item.description,
										}}
									></div>
								</Td>
								<Td>{dateToLocale(item.created_at)}</Td>
								<Td>{item.user_name}</Td>
								<Td>
									<div>
										{isValidPermission(
											"edit",
											permissions
										) && (
											<span
												className="table-actions"
												onClick={() =>
													editElement(item)
												}
											>
												<EditIcon className="icon-edit" />
											</span>
										)}
										{isValidPermission(
											"delete",
											permissions
										) && (
											<span
												className="table-actions icon trash"
												onClick={() =>
													deleteElement(item)
												}
											>
												<DeleteIcon className="icon-delete" />
											</span>
										)}
									</div>
								</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</div>
	);
};

export default translate(UserTable);
