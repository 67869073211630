import React, { useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

import FormFields from "./FormFields";

import classes from "../../audit_program.module.scss";

const SectionOne = ({ data, setData, auditsSchedule, roles, user }) => {
	const [section, setSection] = useState(true);

	return (
		<Card>
			<CardHeader className={`${classes.cardHeader} ${section && classes.active}`} onClick={() => setSection(!section)}>
				SECCION 1: ANTECEDENTES AREA AUDITAR
			</CardHeader>
			{section && (
				<CardBody className={`${classes.cardBody}`}>
					<FormFields data={data} setData={setData} auditsSchedule={auditsSchedule} roles={roles} user={user} />
				</CardBody>
			)}
		</Card>
	);
};

export default SectionOne;
