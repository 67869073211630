import React, { useRef } from "react";
import { translate } from "react-switch-lang";
import api from "../../../../services/api";

import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download-file.svg";
import { ReactComponent as RemoveIcon } from "../../../../assets/icons/remove.svg";

const FormDocuments = (props) => {
	const { data, setData, setIsFetching, indexDetail } = props;

	const refFile = useRef(null);

	const handleSaveDocument = async (e) => {
		const files = e.target.files;

		if (files && files.length !== 0) {
			let newDocuments = data.standard_details[indexDetail].documents ? [...data.standard_details[indexDetail].documents] : [];

			for (var i = 0; i < files.length; i++) {
				// eslint-disable-next-line
				const base64_file = await new Promise((resolve, reject) => {
					if (files[i]) {
						const reader = new FileReader();
						reader.readAsDataURL(files[i]);
						reader.onload = () => resolve(reader.result);
						reader.onerror = (error) => reject(error);
					}
				});

				newDocuments.push({
					id: "",
					name: files[i].name,
					url: base64_file,
					size: files[i].size,
					type: files[i].name.split(".").pop(),
					entity: "audits_verified_detail",
					screen_id: 15,
					entity_id: data.id,
				});
			}

			let newDetails = [...data.standard_details];
			newDetails[indexDetail].documents = newDocuments;

			setData({ ...data, standard_details: newDetails });
		}
	};

	const handleDeleteDocument = (document, i) => {
		let newDocuments = data.standard_details[indexDetail].documents ? [...data.standard_details[indexDetail].documents] : [];

		if (document.id) {
			setIsFetching(true);
			api.post("/client/delete", { document }).then((response) => {
				newDocuments = newDocuments.filter((d) => d.id !== document.id);
				let newDetails = [...data.standard_details];
				newDetails[indexDetail].documents = newDocuments;
				setData({ ...data, standard_details: newDetails });
				setIsFetching(false);
			});
		} else {
			newDocuments.splice(i, 1);
			let newDetails = [...data.standard_details];
			newDetails[indexDetail].documents = newDocuments;
			setData({ ...data, standard_details: newDetails });
		}
	};

	return (
		<div className="form-tab-detail">
			<div>
				<div className="upload-doc" onClick={(e) => refFile.current && refFile.current.click()}>
					Subir Documento
				</div>
				<input style={{ display: "none" }} type="file" multiple ref={refFile} onChange={(e) => handleSaveDocument(e)} />
			</div>

			<div className="table-docs">
				{data &&
					data.standard_details &&
					data.standard_details[indexDetail] &&
					data.standard_details[indexDetail].documents &&
					data.standard_details[indexDetail].documents.map((document, i) => {
						return (
							<div className="table-item" key={i}>
								<div className="index">{i + 1}</div>
								<div className="name">{document.name}</div>
								<div className="actions">
									<DownloadIcon className="file-icons" onClick={() => window.open(document.url, "_blank", "noopener,noreferrer")} />
									<RemoveIcon className="file-icons" onClick={() => handleDeleteDocument(document, i)} />
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default translate(FormDocuments);
