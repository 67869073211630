const days = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];
const months = [
	"Enero",
	"Febrero",
	"Marzo",
	"Abril",
	"Mayo",
	"Junio",
	"Julio",
	"Agosto",
	"Septiembre",
	"Octubre",
	"Noviembre",
	"Diciembre",
];

export const locale = {
	localize: {
		day: (n) => days[n],
		month: (n) => months[n],
	},
	formatLong: {
		date: () => "dd/MM/yyyy",
	},
};
