import React, { useEffect, useState, Fragment } from "react";
import { translate } from "react-switch-lang";
import { useHistory, useParams } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { toast } from "react-toastify";

import Fetching from "components/fetching/Fetching";
import Breadcrumb from "../../../../layout/breadcrumb";
import SectionOne from "./SectionOne/SectionOne";
import SectionTwo from "./SectionTwo/SectionTwo";
import SectionThree from "./SectionThree/SectionThree";
import SectionFour from "./SectionFour/SectionFour";

import api from "services/api";

const baseObject = {
	id: "",
	slug: "",
	date_nonconformity: "",
	folio: "",
	origin: "",
	who_detects: "",
	type: "",
	others: "",
	requirement: "",
	affected_area: "",
	manager_area: "",
	description: "",
	others_areas: "",
	immediate_action: "",
	description_cause: "",
	user_id: "",
	checker_id: "",
	checker_sign: "",
	closer_id: "",
	closer_sign: "",
	customer_id: "",
	is_effective: "",
	feedback_efficacy: "",
	implementer_id: "",
	implementer_sign: "",
	corrective_actions_details: [],
	documents: [],
};

const CorrectiveActionForm = () => {
	let history = useHistory();
	const { corrective_action_id } = useParams();

	const pageName = corrective_action_id !== "create" ? "Editar Acción Correctiva" : "Nueva Acción Correctiva";

	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState({ ...baseObject });
	const [users, setUsers] = useState([]);
	const [userID, setUserID] = useState("");

	useEffect(() => {
		setIsFetching(true);
		api.get("/client/corrective_actions/detail", { corrective_action_id }).then((response) => {
			if (response.corrective_action) {
				setData(response.corrective_action);
			}
			if (response.users) {
				setUsers(response.users);
			}
			setUserID(response.user_id);

			setIsFetching(false);
		});

		document.querySelector(".page-header").className = "page-header close_icon";
		document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon ";
	}, [corrective_action_id]);

	const validSave = () => {
		if (!data.date_nonconformity || data.date_nonconformity === "") {
			toast.error("Debe ingresar la fecha de detección para guardar.");
			return false;
		}
		if (!data.folio || data.folio === "") {
			toast.error("Debe ingresar el folio para guardar.");
			return false;
		}

		return true;
	};

	const handleSave = () => {
		if (validSave()) {
			setIsFetching(true);
			api.post("/client/corrective_actions/save", data)
				.then((response) => {
					setIsFetching(false);
					toast.success("Acción correctiva guardada correctamente.");
					history.push("/corrective_actions");
				})
				.catch((e) => {
					setIsFetching(false);
				});
		}
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<Fragment>
			<Breadcrumb title={pageName} prev_one="Comprobar Norma Auditable" parent="Audithor" />

			<Container fluid={true}>
				<SectionOne data={data} setData={setData} users={users} setIsFetching={setIsFetching} userID={userID} />
				<SectionTwo data={data} setData={setData} users={users} />
				<SectionThree data={data} setData={setData} setIsFetching={setIsFetching} />
				<SectionFour data={data} setData={setData} users={users} setIsFetching={setIsFetching} userID={userID} />

				<div style={{ display: "flex", justifyContent: "center", marginBottom: "3em" }}>
					<Button color="secondary" style={{ marginRight: "10px" }} onClick={() => history.push("/corrective_actions")}>
						Cancelar
					</Button>
					{!data.implementer_sign && (
						<Button color="primary" onClick={() => handleSave()}>
							Guardar
						</Button>
					)}
				</div>
			</Container>
		</Fragment>
	);
};

export default translate(CorrectiveActionForm);
