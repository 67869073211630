import React from "react";
import { Input } from "reactstrap";

import { ReactComponent as RemoveIcon } from "../../../../../assets/icons/remove.svg";

import classes from "../../audit_program.module.scss";

const ProgramItem = ({ index, data, setData, detail, auditors }) => {
	return (
		<div className={classes.body}>
			<div style={{ width: "24%" }}>
				<Input
					className="form-control"
					name="name"
					type="text"
					value={detail && detail.findings ? detail.findings : ""}
					onChange={(e) => {
						let newDetails = [...data.details];
						newDetails[index].findings = e.target.value;
						setData({ ...data, details: newDetails });
					}}
				/>
			</div>
			<div style={{ width: "24%" }}>
				<Input
					className="form-control"
					name="name"
					type="text"
					value={detail && detail.reference ? detail.reference : ""}
					onChange={(e) => {
						let newDetails = [...data.details];
						newDetails[index].reference = e.target.value;
						setData({ ...data, details: newDetails });
					}}
				/>
			</div>
			<div style={{ width: "24%" }}>
				<Input
					className="form-control"
					name="name"
					type="text"
					value={detail && detail.owner ? detail.owner : ""}
					onChange={(e) => {
						let newDetails = [...data.details];
						newDetails[index].owner = e.target.value;
						setData({ ...data, details: newDetails });
					}}
				/>
			</div>
			<div style={{ width: "24%" }}>
				<Input
					className="form-control"
					name="name"
					type="text"
					value={detail && detail.classification ? detail.classification : ""}
					onChange={(e) => {
						let newDetails = [...data.details];
						newDetails[index].classification = e.target.value;
						setData({ ...data, details: newDetails });
					}}
				/>
			</div>
			<div className={classes.body_action}>
				<RemoveIcon className={classes.icon} />
			</div>
		</div>
	);
};

export default ProgramItem;
