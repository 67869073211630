import React, { useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

import Fetching from "../../../../components/fetching/Fetching";
import api from "../../../../services/api";

import FormFields from "./FormFields";
import FormPeriods from "./FormPeriods";

const baseObject = {
	name: "",
	email: "",
	phone: "",
	number: "",
	address: "",
	country_id: "",
	commune_id: "",
	documents: [],
	periods: [],
};

const CustomerForm = (props) => {
	let history = useHistory();
	const { customer_id } = useParams();
	const { pageName, t, countries, allCommunes, callbackAction } = props;
	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState(baseObject);
	const [communes, setCommunes] = useState([]);
	const [selectTab, setSelectTab] = useState("1");

	const handleClose = () => {
		history.push("/admin/customers");
	};

	const handleCountry = (value) => {
		const filter = allCommunes.filter((c) => Number(c.country_id) === Number(value.value));
		setData({
			...data,
			country_id: value.value,
		});
		setCommunes(filter);
	};

	const handleCommune = (value) => {
		setData({
			...data,
			commune_id: value.value,
		});
	};

	const validSave = () => {
		if (!data.name || data.name === "") {
			toast.error(props.t("The name field is required"));
			return false;
		}
		if (data && data.periods.length === 0 && Boolean(data.active)) {
			toast.error("Debe existir un periodo para activar el cliente.");
			return false;
		}
		return true;
	};

	const handleSave = async () => {
		if (validSave()) {
			setIsFetching(true);
			const response = await callbackAction(data);
			if (response) {
				setData(baseObject);
			}
			setIsFetching(false);
		}
	};

	useEffect(() => {
		setIsFetching(false);
		if (customer_id && customer_id !== "create") {
			setIsFetching(true);
			api.get("/admin/customer", { selected: customer_id }).then((response) => {
				setData(response.customer);
				if (data.country_id) {
					setCommunes(allCommunes.filter((c) => Number(c.country_id) === Number(data.country_id)));
				}
				setIsFetching(false);
			});
		} else {
			setData(baseObject);
		}
		// eslint-disable-next-line
	}, [customer_id, allCommunes]);

	return (
		<Modal isOpen={customer_id ? true : false} toggle={handleClose} size="lg" backdrop="static">
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={handleClose}>{t(pageName)}</ModalHeader>
					<ModalBody>
						<Nav tabs>
							<NavItem>
								<NavLink className={selectTab === "1" ? "active" : ""} onClick={() => setSelectTab("1")}>
									{t(`Form`)}
								</NavLink>
							</NavItem>

							{customer_id && customer_id !== "create" && (
								<NavItem>
									<NavLink className={selectTab === "2" ? "active" : ""} onClick={() => setSelectTab("2")}>
										{t(`Periods`)}
									</NavLink>
								</NavItem>
							)}
						</Nav>

						<TabContent activeTab={selectTab}>
							<TabPane className="fade show" tabId="1">
								<FormFields
									t={t}
									data={data}
									setData={setData}
									countries={countries}
									communes={communes}
									allCommunes={allCommunes}
									handleCountry={handleCountry}
									handleCommune={handleCommune}
								/>
							</TabPane>
							{customer_id && customer_id !== "create" && (
								<TabPane tabId="2">
									<FormPeriods data={data} setData={setData} t={t} />
								</TabPane>
							)}
						</TabContent>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={handleClose}>
							{t("Cancel")}
						</Button>
						<Button color="primary" onClick={() => handleSave()}>
							{t("Save")}
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default translate(CustomerForm);
