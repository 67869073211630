import React, { useState, useEffect } from "react";
import { translate } from "react-switch-lang";
import { Col, Input, Label, Row, Button } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { dateTimeFormat } from "../../../../services/utils";
import { locale } from "../../constants";
import { useHistory } from "react-router-dom";
import api from "../../../../services/api";

const typeAudit = [
	{ value: "INTERNA", label: "INTERNA" },
	{ value: "EXTERNA", label: "EXTERNA" },
];

const Fields = (props) => {
	let history = useHistory();
	const { t, data, setData, standards, auditors, setIsFetching } = props;

	const [standardsDetail, setStandardsDetail] = useState([]);

	useEffect(() => {
		const standard = standards.find(
			(sta) => Number(sta.value) === Number(data.standard_id)
		);

		setStandardsDetail(standard ? standard.standard_details : []);

		// eslint-disable-next-line
	}, [data.standard_id]);

	const handleStandard = (value) => {
		setData({
			...data,
			standard_id: value.value,
			standard_details: [],
		});
	};

	const handleStandardDetail = (value) => {
		setData({
			...data,
			standard_details: value,
		});
	};

	const handleStartDate = (value) => {
		const start_date = dateTimeFormat(new Date(value));

		setData({
			...data,
			start_date: start_date,
		});
	};

	const handleEndDate = (value) => {
		const endDate = new Date(value);
		if (endDate.getHours() === 0 && endDate.getMinutes() === 0) {
			endDate.setHours(23);
			endDate.setMinutes(59);
			endDate.setSeconds(59);
		}

		const end_date = dateTimeFormat(endDate);

		setData({
			...data,
			end_date: end_date,
		});
	};

	const handleTypeAudit = (value) => {
		setData({
			...data,
			type_audit: value.value,
		});
	};

	const handleAuditors = (value) => {
		setData({
			...data,
			auditors: value,
		});
	};

	const handleCreateProgram = () => {
		setIsFetching(true);
		api.post("/client/audits_program/create", { data }).then((response) => {
			history.push("/audit_program/" + response.audits_program.slug);
		});
	};

	return (
		<div className="form-tab-detail">
			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="standard_id">{t("Standard")}</Label>
					<Select
						defaultValue={
							data.standard_id
								? standards.filter(
										(c) =>
											Number(c.value) ===
											Number(data.standard_id)
								  )
								: ""
						}
						placeholder={<div>{t("Select")}...</div>}
						options={standards}
						onChange={(value) => handleStandard(value)}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="standard_id">{t("Standard Detail")}</Label>
					<Select
						value={
							data.standard_details.length > 0
								? data.standard_details
								: []
						}
						placeholder={<div>{t("Select")}...</div>}
						options={standardsDetail}
						isClearable={false}
						onChange={(value) => handleStandardDetail(value)}
						isMulti
					/>
				</Col>
			</div>

			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="start_date">{t("Start Date")}</Label>
					<DatePicker
						className="datepicker-here form-control"
						selected={
							data && data.start_date
								? new Date(data.start_date)
								: null
						}
						onChange={(value) => handleStartDate(value)}
						showTimeInput
						locale={locale}
						dateFormat="dd-MM-yyy"
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="start_date">Hora Inicio</Label>
					<Input
						value={
							data && data.start_date
								? data.start_date.substring(11, 19)
								: ""
						}
						readOnly={true}
					/>
				</Col>
			</div>

			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="end_date">{t("End Date")}</Label>
					<DatePicker
						className="datepicker-here form-control"
						selected={
							data && data.end_date
								? new Date(data.end_date)
								: null
						}
						onChange={(value) => handleEndDate(value)}
						showTimeInput
						locale={locale}
						dateFormat="dd-MM-yyy"
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="end_date">Hora Finalización</Label>
					<Input
						value={
							data && data.end_date
								? data.end_date.substring(11, 19)
								: ""
						}
						readOnly={true}
					/>
				</Col>
			</div>

			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="standard_id">{t("Type Audit")}</Label>
					<Select
						defaultValue={
							data.type_audit
								? typeAudit.filter(
										(c) => c.value === data.type_audit
								  )
								: ""
						}
						placeholder={<div>{t("Select")}...</div>}
						options={typeAudit}
						onChange={(value) => handleTypeAudit(value)}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="standard_id">{t("Auditors")}</Label>
					<Select
						value={data.auditors.length > 0 ? data.auditors : []}
						placeholder={<div>{t("Select")}...</div>}
						options={auditors}
						isClearable={false}
						onChange={(value) => handleAuditors(value)}
						isMulti
					/>
				</Col>
			</div>

			<div className="form-row">
				<Label htmlFor="grade_course">{t("Observations")}</Label>
				<Col md="12 mb-3">
					<CKEditor
						editor={ClassicEditor}
						data={data && data.description ? data.description : ""}
						onChange={(event, editor) => {
							setData({ ...data, description: editor.getData() });
						}}
					/>
				</Col>
			</div>

			{data.id && !data.audit_program_id && (
				<Row>
					<Col
						md={12}
						style={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Button
							color="primary"
							style={{
								width: "50%",
							}}
							onClick={() => handleCreateProgram()}
						>
							Generar Programa de Auditoría
						</Button>
					</Col>
				</Row>
			)}
			{data.id && data.audit_program_id && (
				<Row>
					<Col
						md={12}
						style={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Button
							color="dark"
							style={{
								width: "50%",
							}}
							onClick={() =>
								history.push(
									"/audit_program/" + data.audit_program.slug
								)
							}
						>
							Ver Programa de Auditoría
						</Button>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default translate(Fields);
