import React from "react";
import { useHistory } from "react-router-dom";
import { t, translate } from "react-switch-lang";

import classes from "./error404.module.scss";

import { ReactComponent as Icon404 } from "../../assets/icons/404.svg";

const Error404 = () => {
	let history = useHistory();

	return (
		<>
			<div className={classes.base_error}>
				<div>
					<h1 className={classes.message}>
						{t("Sorry, The Page Not Found")}
					</h1>

					<Icon404 className={classes.image} />
				</div>
			</div>
			<div className={classes.base_error_buttom}>
				<div
					className={classes.button_back}
					onClick={() => history.goBack()}
				>
					{t("Go back")}
				</div>
			</div>
		</>
	);
};

export default translate(Error404);
