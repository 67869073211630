import React, { Fragment, useState } from "react";
import man from "../../assets/images/dashboard/user.png";
import { LogIn, User, Search } from "react-feather";
import {
	setTranslations,
	setDefaultLanguage,
	setLanguageCookie,
	translate,
} from "react-switch-lang";

import { Account, LogOut } from "../../constant";

import en from "../../assets/i18n/en.json";
import es from "../../assets/i18n/es.json";

setTranslations({ en, es });
setDefaultLanguage("es");
setLanguageCookie();

const Rightbar = (props) => {
	const [searchresponsive, setSearchresponsive] = useState(false);

	const SeacrhResposive = (searchresponsive) => {
		if (searchresponsive) {
			setSearchresponsive(!searchresponsive);
			document.querySelector(".search-full").classList.add("open");
		} else {
			setSearchresponsive(!searchresponsive);
			document.querySelector(".search-full").classList.remove("open");
		}
	};

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("full_name");
		localStorage.removeItem("expiration_date");
		localStorage.removeItem("customer");
		localStorage.removeItem("customer_id");
		localStorage.removeItem("screens");

		window.location.href = `${process.env.PUBLIC_URL}/login`;
	};

	return (
		<Fragment>
			<div className="nav-right col-8 pull-right right-header p-0">
				<ul className="nav-menus">
					{/* Language */}

					{/* Search */}
					<li>
						<span className="header-search">
							<Search
								onClick={() =>
									SeacrhResposive(searchresponsive)
								}
							/>
						</span>
					</li>
					{/* Notification */}

					{/* Profile */}
					<li className="profile-nav onhover-dropdown p-0">
						<div className="media profile-media">
							<img className="b-r-10" src={man} alt="" />
							<div className="media-body">
								<span>{localStorage.getItem("full_name")}</span>
								<p className="mb-0 font-roboto">
									{localStorage.getItem("customer")}{" "}
									<i className="middle fa fa-angle-down"></i>
								</p>
							</div>
						</div>
						<ul className="profile-dropdown onhover-show-div">
							<li>
								<User />
								<span>{props.t(Account)} </span>
							</li>
							<li onClick={() => handleLogout()}>
								<LogIn />
								<span>{props.t(LogOut)}</span>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</Fragment>
	);
};
export default translate(Rightbar);
