import React from "react";
import { translate } from "react-switch-lang";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { ReactComponent as EditIcon } from "../../../../assets/icons/editar.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/basura.svg";
import { dateToLocale, isValidPermission } from "../../../../services/utils";
import { Badge } from "reactstrap";

const VerifyAuditsTable = (props) => {
	const { editElement, deleteElement, data, permissions } = props;

	return (
		<div className="base-div">
			<Table className="base-table">
				<Thead>
					<Tr>
						<Th>Estado</Th>
						<Th>Norma</Th>
						<Th>Código</Th>
						<Th>Fecha Verificación</Th>
						<Th>Descripción</Th>
						<Th>Usuario</Th>
						<Th>Acciones</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data &&
						data.map((item, i) => {
							return (
								<Tr key={i}>
									<Td>
										{item && item.confirmed ? (
											<Badge color="success">Completada</Badge>
										) : (
											<Badge color="secondary">Ingresada</Badge>
										)}
									</Td>
									<Td>{item.standard ? item.standard.title : ""}</Td>
									<Td>{item.code ? item.code : ""}</Td>
									<Td>{item.verification_date ? dateToLocale(item.verification_date) : ""}</Td>
									<Td>
										{item.description ? (
											<div
												dangerouslySetInnerHTML={{
													__html: item.description,
												}}
											></div>
										) : (
											""
										)}
									</Td>
									<Td>{item.user ? item.user.name : ""}</Td>
									<Td>
										<div>
											{isValidPermission("edit", permissions) && (
												<span className="table-actions" onClick={() => editElement(item)}>
													<EditIcon className="icon-edit" />
												</span>
											)}
											{isValidPermission("delete", permissions) && (
												<span className="table-actions icon trash" onClick={() => deleteElement(item)}>
													<DeleteIcon className="icon-delete" />
												</span>
											)}
										</div>
									</Td>
								</Tr>
							);
						})}
				</Tbody>
			</Table>
		</div>
	);
};

export default translate(VerifyAuditsTable);
