import React from "react";
import Select from "react-select";
import { Input } from "reactstrap";

import { ReactComponent as RemoveIcon } from "../../../../../assets/icons/remove.svg";

import classes from "../../audit_program.module.scss";

const ProgramItem = ({ index, data, setData, detail, auditors }) => {
	return (
		<div className={classes.body}>
			<div className={classes.body_date}>
				<Input
					className="form-control"
					name="name"
					type="date"
					value={detail && detail.date_detail ? detail.date_detail : ""}
					onChange={(e) => {
						let newDetails = [...data.details];
						newDetails[index].date_detail = e.target.value;
						setData({ ...data, details: newDetails });
					}}
				/>
			</div>
			<div className={classes.body_time}>
				<Input
					className="form-control"
					name="name"
					type="time"
					value={detail && detail.time_detail ? detail.time_detail : ""}
					onChange={(e) => {
						let newDetails = [...data.details];
						newDetails[index].time_detail = e.target.value;
						setData({ ...data, details: newDetails });
					}}
				/>
			</div>
			<div className={classes.body_function}>
				<Input
					className="form-control"
					name="name"
					type="text"
					value={detail && detail.departament ? detail.departament : ""}
					onChange={(e) => {
						let newDetails = [...data.details];
						newDetails[index].departament = e.target.value;
						setData({ ...data, details: newDetails });
					}}
				/>
			</div>
			<div className={classes.body_auditor}>
				<Select
					defaultValue={detail.auditor_id ? auditors.filter((a) => Number(a.value) === Number(detail.auditor_id)) : ""}
					placeholder={<div>Seleccione...</div>}
					options={auditors}
					onChange={(e) => {
						let newDetails = [...data.details];
						newDetails[index].auditor_id = e.value;
						setData({ ...data, details: newDetails });
					}}
				/>
			</div>
			<div className={classes.body_action}>
				<RemoveIcon className={classes.icon} />
			</div>
		</div>
	);
};

export default ProgramItem;
