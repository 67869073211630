import React, { useState, useEffect } from "react";
import { translate } from "react-switch-lang";
import { Row, Col, Input } from "reactstrap";

import { ReactComponent as AddIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as ExportIcon } from "../../assets/icons/arrow-down.svg";
import { isValidPermissionPage } from "../../services/utils";

const icons = {
	AddIcon: <AddIcon className="icon" />,
	ExportIcon: <ExportIcon className="icon" />,
};

const Search = (props) => {
	const { search, setSearch, to, from, total, t, permissions, handleCreate, handleExport } = props;
	const actions = [
		{
			key: "create",
			label: "Create",
			icon: "AddIcon",
			function: handleCreate,
		},
		{
			key: "export",
			label: "Export",
			icon: "ExportIcon",
			function: handleExport,
		},
	];

	const [realPermissions, setRealPermissions] = useState([]);

	useEffect(() => {
		if (realPermissions.length === 0) {
			const p = isValidPermissionPage(actions, permissions);
			setRealPermissions(p);
		}
		// eslint-disable-next-line
	}, [permissions]);

	return (
		<Col sm="12" className="header-table">
			<Row>
				{realPermissions.map((action, i) => {
					return (
						<Col key={i} sm={6 / realPermissions.length} className="main-action" onClick={() => action.function()}>
							<div className="action">
								{icons[action.icon]} {t(action.label)}
							</div>
						</Col>
					);
				})}

				<Col sm={`3 ${realPermissions.length === 0 && "offset-md-6"} `} className="label-pagination">
					{from && to && `${t("PaginationShowing")} ${from} - ${to} ${t("PaginationOf")} ${total}`}
				</Col>
				<Col sm="3">
					<Input
						className="form-control"
						type="text"
						placeholder={t("Search")}
						defaultValue={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</Col>
			</Row>
		</Col>
	);
};

export default translate(Search);
