import React, { useState } from "react";
import { Input } from "reactstrap";

import { ReactComponent as RemoveIcon } from "assets/icons/remove.svg";
import { ReactComponent as CheckIcon } from "assets/fonts/feather/check-square.svg";
import { ReactComponent as XIcon } from "assets/fonts/feather/x-square.svg";

import classes from "../standard.module.scss";
import api from "services/api";
import Fetching from "components/fetching/Fetching";

const FormQuestions = ({ questions, setQuestions }) => {
	const [newQuestion, setNewQuestion] = useState("");
	const [createQuestion, setCreateQuestion] = useState(false);
	const [isFetching, setIsFetching] = useState(false);

	const saveQuestion = (text_question) => {
		setIsFetching(true);
		api.post("/client/standards/questions/save", { question: text_question }).then((response) => {
			setNewQuestion("");
			setCreateQuestion(false);
			setQuestions(response);
			setIsFetching(false);
		});
	};

	const deleteQuestion = (question_id) => {
		setIsFetching(true);
		api.post("/client/standards/questions/delete", { question: question_id }).then((response) => {
			setNewQuestion("");
			setCreateQuestion(false);
			setQuestions(response);
			setIsFetching(false);
		});
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<div className="form-tab-detail">
			{!createQuestion && (
				<div>
					<div className="new_question" onClick={(e) => setCreateQuestion(true)}>
						Nueva Pregunta
					</div>
				</div>
			)}

			{createQuestion && (
				<div className={classes.new_question} style={{ marginTop: "5px", display: "flex" }}>
					<div className={classes.description}>
						<Input
							className="form-control"
							name="title"
							type="text"
							value={newQuestion}
							onChange={(e) => setNewQuestion(e.target.value)}
						/>
					</div>
					<div
						className={`${classes.div_icon} ${classes.check}`}
						onClick={() => {
							saveQuestion(newQuestion);
						}}
					>
						<CheckIcon className={classes.icon} />
					</div>
					<div
						className={`${classes.div_icon} ${classes.times}`}
						onClick={() => {
							setNewQuestion("");
							setCreateQuestion(false);
						}}
					>
						<XIcon className={classes.icon} />
					</div>
				</div>
			)}

			<hr />
			<div className="table-docs">
				{questions &&
					questions.map((question, i) => {
						return (
							<div className="table-item" key={i}>
								<div className="index">{i + 1}</div>
								<div className="name">{question.label}</div>
								<div className="actions">
									<RemoveIcon className="file-icons" onClick={() => deleteQuestion(question.value)} />
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default FormQuestions;
