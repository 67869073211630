import React from "react";
import Select from "react-select";
import { Col, Label, Input } from "reactstrap";

import { translate } from "react-switch-lang";
import { datetimeToLocale } from "../../../../../services/utils";

const FormFields = ({ data, setData, auditsSchedule, roles, user }) => {
	return (
		<div className="form-tab-detail">
			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="audit_schedule_id">Nombre</Label>
					<Input
						className="form-control"
						name="name"
						type="text"
						value={data && data.user && data.user.label ? data.user.label : user ? user.name : ""}
						readOnly={true}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="audit_schedule_id">Cargo</Label>
					<Select
						isMulti
						defaultValue={
							data && data.user && data.user.roles
								? roles.filter((r) => data.user.roles.includes(r.value))
								: user
								? roles.filter((r) => user.roles.includes(r.value))
								: []
						}
						placeholder={<div>Seleccione...</div>}
						options={roles}
						isDisabled={true}
					/>
				</Col>

				{window.location.pathname.includes("/audit_program/") && (
					<>
						<Col md="6 mb-3">
							<Label htmlFor="audit_schedule_id">Norma Planificada</Label>
							<Select
								defaultValue={
									data.audit_schedule_id
										? auditsSchedule.filter((c) => Number(c.audit_schedule_id) === Number(data.audit_schedule_id))
										: ""
								}
								placeholder={<div>Seleccione...</div>}
								options={auditsSchedule}
								onChange={(value) => {
									setData({
										...data,
										audit_schedule_id: value.audit_schedule_id,
										audit_schedule: value,
										standard_id: value.value,
										standard_details: value.standard_details,
									});
								}}
								isDisabled={data.id ? true : false}
							/>
						</Col>

						<Col md="6 mb-3">
							<Label htmlFor="audit_schedule_id">Detalle</Label>
							<Select
								isMulti
								defaultValue={data ? data.standard_details : []}
								value={data ? data.standard_details : []}
								placeholder={<div>Seleccione...</div>}
								options={data ? data.standard_details : []}
								isDisabled={true}
							/>
						</Col>

						<Col md="6 mb-3">
							<Label htmlFor="audit_schedule_id">Fecha Inicio</Label>
							<Input
								className="form-control"
								name="name"
								type="text"
								value={
									data && data.audit_schedule && data.audit_schedule.start_date
										? datetimeToLocale(data.audit_schedule.start_date)
										: ""
								}
								readOnly={true}
							/>
						</Col>

						<Col md="6 mb-3">
							<Label htmlFor="audit_schedule_id">Fecha Finalización</Label>
							<Input
								className="form-control"
								name="name"
								type="text"
								value={
									data && data.audit_schedule && data.audit_schedule.end_date ? datetimeToLocale(data.audit_schedule.end_date) : ""
								}
								readOnly={true}
							/>
						</Col>
					</>
				)}

				<Col md="6 mb-3">
					<Label htmlFor="audit_schedule_id">Sección</Label>
					<Input
						className="form-control"
						name="section"
						type="text"
						value={data && data.section ? data.section : ""}
						onChange={(e) =>
							setData({
								...data,
								section: e.target.value,
							})
						}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="audit_schedule_id">Área</Label>
					<Input
						className="form-control"
						name="area"
						type="text"
						value={data && data.area ? data.area : ""}
						onChange={(e) =>
							setData({
								...data,
								area: e.target.value,
							})
						}
					/>
				</Col>
			</div>
		</div>
	);
};

export default translate(FormFields);
