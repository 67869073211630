import React from "react";
import { translate } from "react-switch-lang";
import { Row, Col, Input } from "reactstrap";

const FormPermissions = (props) => {
	const { t, data, setData } = props;

	const handleChange = (e, i, j) => {
		let newData = JSON.parse(JSON.stringify(data));
		newData.permissions[i].details[j].value = e.target.checked;
		setData(newData);
	};

	return (
		<div className="form-tab-detail">
			<Row>
				<Col sm="12" style={{ padding: "10px" }}>
					<table style={{ width: "100%", textAlign: "center" }}>
						<thead>
							<tr>
								<th>{t("Screen")}</th>
								<th>{t("Create")}</th>
								<th>{t("Edit")}</th>
								<th>{t("Show")}</th>
								<th>{t("Delete")}</th>
								<th>{t("Export")}</th>
							</tr>
						</thead>
						<tbody>
							{data &&
								data.permissions.map((screen, i) => {
									return (
										<tr key={i}>
											<td style={{ textAlign: "left" }}>
												{t(screen.screen)}
											</td>
											{screen.details.map((detail, j) => {
												return (
													<td key={j}>
														<Input
															className="checkbox_animated"
															id={`screen_${i}${j}`}
															type="checkbox"
															checked={
																detail.value
															}
															onChange={(e) =>
																handleChange(
																	e,
																	i,
																	j
																)
															}
														/>
													</td>
												);
											})}
										</tr>
									);
								})}
						</tbody>
					</table>
				</Col>
			</Row>
		</div>
	);
};

export default translate(FormPermissions);
