import React from "react";
import { translate } from "react-switch-lang";
import {
	Row,
	Col,
	Pagination,
	PaginationItem,
	PaginationLink,
} from "reactstrap";

const Paginator = (props) => {
	const { page, setPage, lastPage } = props;

	return (
		<Col sm="12" className="base-paginator">
			<Row>
				<Col sm="3 offset-md-9">
					<Pagination className="pagination-primary pagination-right">
						{page !== 1 && (
							<PaginationItem>
								<PaginationLink
									onClick={() => setPage(page - 1)}
								>
									<span aria-hidden="true">«</span>
									<span className="sr-only"></span>
								</PaginationLink>
							</PaginationItem>
						)}

						{page !== 1 && (
							<PaginationItem>
								<PaginationLink
									onClick={() => setPage(page - 1)}
								>
									{page - 1}
								</PaginationLink>
							</PaginationItem>
						)}

						<PaginationItem active>
							<PaginationLink
								onClick={() =>
									console.info("Current Page", page)
								}
							>
								{page}
							</PaginationLink>
						</PaginationItem>

						{lastPage >= page + 1 && (
							<PaginationItem>
								<PaginationLink
									onClick={() => setPage(page + 1)}
								>
									{page + 1}
								</PaginationLink>
							</PaginationItem>
						)}

						{lastPage >= page + 1 && (
							<PaginationItem>
								<PaginationLink
									onClick={() => setPage(page + 1)}
								>
									<span aria-hidden="true">»</span>
									<span className="sr-only"></span>
								</PaginationLink>
							</PaginationItem>
						)}
					</Pagination>
				</Col>
			</Row>
		</Col>
	);
};

export default translate(Paginator);
