import React from "react";
import { translate } from "react-switch-lang";
import { Row, Col, Input } from "reactstrap";

const FormRoles = (props) => {
	const { t, data, setData } = props;

	const handleChange = (e, i) => {
		let newData = JSON.parse(JSON.stringify(data));
		newData.user_roles[i].assigned = e.target.checked;
		setData(newData);
	};

	return (
		<div className="form-tab-detail">
			<Row>
				{data &&
					data.user_roles.map((role, i) => {
						return (
							<Col key={i} style={{ padding: "10px" }}>
								<Input
									className="checkbox_animated"
									id={`role_${i}`}
									type="checkbox"
									checked={role.assigned}
									onChange={(e) => handleChange(e, i)}
								/>
								{t(role.name)}
							</Col>
						);
					})}
			</Row>
		</div>
	);
};

export default translate(FormRoles);
