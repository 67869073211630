import React from "react";
import { translate } from "react-switch-lang";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { ReactComponent as EditIcon } from "../../../../assets/icons/editar.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/basura.svg";
import { isValidPermission } from "../../../../services/utils";

const ScreenTable = (props) => {
	const { editElement, deleteElement, data, t, permissions } = props;

	return (
		<div className="base-div">
			<Table className="base-table">
				<Thead>
					<Tr>
						<Th>{t("Label")}</Th>
						<Th>{t("Description")}</Th>
						<Th>{t("Index")}</Th>
						<Th>{t("Icon")}</Th>
						<Th>{t("Path")}</Th>
						<Th>{t("Actions")}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, i) => {
						return (
							<Tr key={i}>
								<Td>{item.label}</Td>
								<Td>{item.description}</Td>
								<Td>{item.indice}</Td>
								<Td>{item.icon}</Td>
								<Td>{item.path}</Td>
								<Td>
									<div>
										{isValidPermission(
											"edit",
											permissions
										) && (
											<span
												className="table-actions"
												onClick={() =>
													editElement(item)
												}
											>
												<EditIcon className="icon-edit" />
											</span>
										)}
										{isValidPermission(
											"delete",
											permissions
										) && (
											<span
												className="table-actions icon trash"
												onClick={() =>
													deleteElement(item)
												}
											>
												<DeleteIcon className="icon-delete" />
											</span>
										)}
									</div>
								</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</div>
	);
};

export default translate(ScreenTable);
