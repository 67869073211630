import React from "react";
import { translate } from "react-switch-lang";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { ReactComponent as EditIcon } from "../../../../assets/icons/editar.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/basura.svg";
import { isValidPermission, dateToLocale } from "../../../../services/utils";
import { Badge } from "reactstrap";

const CustomerTable = (props) => {
	const { editElement, deleteElement, data, t, permissions } = props;

	return (
		<div className="base-div">
			<Table className="base-table">
				<Thead>
					<Tr>
						<Th>{t("Active")}</Th>
						<Th>{t("Name")}</Th>
						<Th>{t("Email")}</Th>
						<Th>{t("Phone")}</Th>
						<Th>{t("Commune")}</Th>
						<Th>{t("Registration Date")}</Th>
						<Th>{t("Expiration Date")}</Th>
						<Th>{t("Actions")}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, i) => {
						return (
							<Tr key={i}>
								<Td>{item.active === "1" ? <Badge color={"success"}>Activo</Badge> : <Badge color={"danger"}>Inactivo</Badge>}</Td>
								<Td>{item.name}</Td>
								<Td>{item.email}</Td>
								<Td>{item.phone}</Td>
								<Td>{item.commune && item.commune.name}</Td>
								<Td>{dateToLocale(item.created_at)}</Td>
								<Td>{dateToLocale(item.expiration_date)}</Td>
								<Td>
									<div>
										{isValidPermission("edit", permissions) && (
											<span className="table-actions" onClick={() => editElement(item)}>
												<EditIcon className="icon-edit" />
											</span>
										)}
										{isValidPermission("delete", permissions) && (
											<span className="table-actions icon trash" onClick={() => deleteElement(item)}>
												<DeleteIcon className="icon-delete" />
											</span>
										)}
									</div>
								</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</div>
	);
};

export default translate(CustomerTable);
