import React from "react";
import { translate } from "react-switch-lang";
import { Row, Col, Input } from "reactstrap";

const Screens = (props) => {
	const { t, data, setData } = props;

	const handleChange = (e, i) => {
		let newData = JSON.parse(JSON.stringify(data));
		newData.permission_screens[i].permission = e.target.checked;
		setData(newData);
	};

	return (
		<div className="form-tab-detail">
			<Row>
				{data &&
					data.permission_screens.map((screen, i) => {
						return (
							<Col key={i} style={{ padding: "10px" }}>
								<Input
									className="checkbox_animated"
									id={`scrren_${i}`}
									type="checkbox"
									checked={screen.permission}
									onChange={(e) => handleChange(e, i)}
								/>
								{t(screen.label)}
							</Col>
						);
					})}
			</Row>
		</div>
	);
};

export default translate(Screens);
