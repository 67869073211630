import React from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Col, Label, Input, Button } from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { translate } from "react-switch-lang";
import { getNow } from "services/utils";
import api from "services/api";

const origins = [
	{ value: "Servicio / Proceso", label: "Servicio / Proceso" },
	{ value: "Auditoría", label: "Auditoría" },
	{ value: "Objetivo / Indicador", label: "Objetivo / Indicador" },
	{ value: "Recomendación / Queja del Cliente", label: "Recomendación / Queja del Cliente" },
	{ value: "Proveedor", label: "Proveedor" },
];

const types = [
	{ value: "NC CRITICA", label: "NC CRITICA" },
	{ value: "NC MAYOR", label: "NC MAYOR" },
	{ value: "NC MENOR", label: "NC MENOR" },
	{ value: "K.O.", label: "K.O." },
	{ value: "OBSERVACIÓN", label: "OBSERVACIÓN" },
];

const Fields = ({ data, setData, users, userID, setIsFetching }) => {
	const changeDescription = (editor) => {
		const newData = { ...data, description: editor.getData() };
		setData(newData);
	};

	const changeOthersAreas = (editor) => {
		const newData = { ...data, others_areas: editor.getData() };
		setData(newData);
	};

	const changeInmediateAction = (editor) => {
		const newData = { ...data, immediate_action: editor.getData() };
		setData(newData);
	};

	const changeDescriptionCause = (editor) => {
		const newData = { ...data, description_cause: editor.getData() };
		setData(newData);
	};

	const validChecker = () => {
		if (!data.checker_id) {
			toast.error("Debe seleccionar un usuario responsable del cumplimiento de la acción inmediata.");
			return false;
		}
		if (Number(data.checker_id) !== Number(userID)) {
			toast.error("El usuario responsable del cumplimiento de la acción inmediata debe ser el usuario logueado.");
			return false;
		}
		if (!data.date_nonconformity) {
			toast.error("Debe ingresar la fecha de detección del hallazgo/no conformidad.");
			return false;
		}
		if (!data.folio) {
			toast.error("Debe ingresar el folio.");
			return false;
		}
		if (!data.origin) {
			toast.error("Debe seleccionar la fuente.");
			return false;
		}
		if (!data.who_detects) {
			toast.error("Debe ingresar quien detecta.");
			return false;
		}
		if (!data.type) {
			toast.error("Debe seleccionar el tipo.");
			return false;
		}
		if (!data.affected_area) {
			toast.error("Debe ingresar el área afectada.");
			return false;
		}
		if (!data.manager_area) {
			toast.error("Debe ingresar el responsable del área.");
			return false;
		}
		if (!data.description) {
			toast.error("Debe ingresar la descripción del hallazgo.");
			return false;
		}
		if (!data.immediate_action) {
			toast.error("Debe ingresar la acción inmediata.");
			return false;
		}

		return true;
	};

	const handleConfirmChecker = () => {
		if (!validChecker()) return;

		setIsFetching(true);
		api.post("/client/corrective_actions/confirm_checker", { ...data })
			.then((response) => {
				setIsFetching(false);
				toast.success("Se ha confirmado el usuario responsable del cumplimiento de la acción inmediata");
				setData({ ...data, checker_date: getNow(), checker_sign: true });
			})
			.catch((e) => {
				setIsFetching(false);
			});
	};

	return (
		<div className="form-tab-detail">
			<div className="form-row">
				<Col md="9 mb-3">
					<Label htmlFor="date_nonconformity">Fecha de Detección del Hallazgo/No Conformidad</Label>
					<Input
						className="form-control"
						name="date_nonconformity"
						type="date"
						value={data && data.date_nonconformity ? data.date_nonconformity : ""}
						onChange={(e) =>
							setData({
								...data,
								date_nonconformity: e.target.value,
							})
						}
						readOnly={data.checker_sign ? true : false}
					/>
				</Col>
				<Col md="3 mb-3">
					<Label htmlFor="folio">Folio</Label>
					<Input
						className="form-control"
						name="folio"
						type="text"
						value={data && data.folio && data.folio ? data.folio : ""}
						onChange={(e) =>
							setData({
								...data,
								folio: e.target.value,
							})
						}
						readOnly={data.id ? true : false}
					/>
				</Col>
				<Col md="4 mb-3">
					<Label htmlFor="origin">Fuente</Label>
					<Select
						defaultValue={data.origin ? origins.filter((c) => String(c.value) === String(data.origin)) : ""}
						placeholder={<div>Seleccione...</div>}
						options={origins}
						onChange={(value) => {
							setData({
								...data,
								origin: value.value,
							});
						}}
						isDisabled={data.checker_sign ? true : false}
					/>
				</Col>
				<Col md="4 mb-3">
					<Label htmlFor="origin">Quien Detecta?</Label>
					<Input
						className="form-control"
						name="who_detects"
						type="text"
						value={data && data.who_detects && data.who_detects ? data.who_detects : ""}
						onChange={(e) =>
							setData({
								...data,
								who_detects: e.target.value,
							})
						}
						readOnly={data.checker_sign ? true : false}
					/>
				</Col>
				<Col md="4 mb-3">
					<Label htmlFor="type">Tipo</Label>
					<Select
						defaultValue={data.type ? types.filter((c) => String(c.value) === String(data.type)) : ""}
						placeholder={<div>Seleccione...</div>}
						options={types}
						onChange={(value) => {
							setData({
								...data,
								type: value.value,
							});
						}}
						isDisabled={data.checker_sign ? true : false}
					/>
				</Col>

				<hr style={{ borderTop: "1px solid #0e75ba", width: "100%" }} />

				<Col md="6 mb-3">
					<Label htmlFor="affected_area">Área Afectada</Label>
					<Input
						className="form-control"
						name="affected_area"
						type="text"
						value={data && data.affected_area && data.affected_area ? data.affected_area : ""}
						onChange={(e) =>
							setData({
								...data,
								affected_area: e.target.value,
							})
						}
						readOnly={data.checker_sign ? true : false}
					/>
				</Col>
				<Col md="6 mb-3">
					<Label htmlFor="manager_area">Responsable Área</Label>
					<Input
						className="form-control"
						name="manager_area"
						type="text"
						value={data && data.manager_area && data.manager_area ? data.manager_area : ""}
						onChange={(e) =>
							setData({
								...data,
								manager_area: e.target.value,
							})
						}
						readOnly={data.checker_sign ? true : false}
					/>
				</Col>
				<Col md="12 mb-3">
					<Label htmlFor="audit_schedule_id">Descripción del Hallazgo</Label>
					<CKEditor
						editor={ClassicEditor}
						data={data && data.description ? data.description : ""}
						onChange={(event, editor) => {
							changeDescription(editor);
						}}
						disabled={data.checker_sign ? true : false}
					/>
				</Col>
				<Col md="12 mb-3">
					<Label htmlFor="audit_schedule_id">Otras áreas donde pueda presentarse la misma no conformidad</Label>
					<CKEditor
						editor={ClassicEditor}
						data={data && data.others_areas ? data.others_areas : ""}
						onChange={(event, editor) => {
							changeOthersAreas(editor);
						}}
					/>
				</Col>
				<Col md="12 mb-3">
					<Label htmlFor="immediate_action">Acción inmediata (contención)</Label>
					<CKEditor
						editor={ClassicEditor}
						data={data && data.immediate_action ? data.immediate_action : ""}
						onChange={(event, editor) => {
							changeInmediateAction(editor);
						}}
						disabled={data.checker_sign ? true : false}
					/>
				</Col>
				<Col md="6 mb-3">
					<Label htmlFor="affected_area">Usuario responsable del cumplimiento acción inmediata</Label>
					<Select
						defaultValue={data.checker_id ? users.filter((c) => Number(c.value) === Number(data.checker_id)) : ""}
						placeholder={<div>Seleccione...</div>}
						options={users}
						onChange={(value) => {
							setData({
								...data,
								checker_id: value.value,
							});
						}}
					/>
				</Col>
				<Col md="6 mb-3">
					<Label htmlFor="affected_area">Confirmación responsable del cumplimiento acción inmediata</Label>
					<div>
						{!data.checker_sign ? (
							Number(data.checker_id) === Number(userID) && (
								<Button color="primary" onClick={() => handleConfirmChecker()}>
									Confirmar
								</Button>
							)
						) : (
							<Button color="success" disabled>
								Confirmado | {data.checker_date}
							</Button>
						)}
					</div>
				</Col>
				<Col md="12 mb-3">
					<Label htmlFor="description_cause"> Descripción de la Causa Raíz </Label>
					<CKEditor
						editor={ClassicEditor}
						data={data && data.description_cause ? data.description_cause : ""}
						onChange={(event, editor) => {
							changeDescriptionCause(editor);
						}}
					/>
				</Col>
			</div>
		</div>
	);
};

export default translate(Fields);
