import axios from "axios";
import { toast } from "react-toastify";

export default {
	setupInterceptors: (store, props) => {
		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error && error.hasOwnProperty("response")) {
					switch (error.response.status) {
						case 400:
							toast.error(props.t(error.response.data.message), {
								toastId: "error-400-id",
							});

							break;
						case 409:
							break;
						case 401:
							toast.error(
								props.t(
									"Your session has expired, please log in again to continue"
								),
								{
									toastId: "error-400-id",
								}
							);
							localStorage.removeItem("token");
							localStorage.removeItem("full_name");
							localStorage.removeItem("expiration_date");
							localStorage.removeItem("customer");
							localStorage.removeItem("customer_id");
							localStorage.removeItem("screens");

							window.location.href = `${process.env.PUBLIC_URL}/login`;

							break;
						case 500:
							console.error("500");
							break;
						default:
							toast.error(
								props.t(
									"An unexpected error has occurred, please contact support"
								),
								{
									toastId: "error-400-id",
								}
							);
							break;
					}
				}

				return Promise.reject(error);
			}
		);
	},
};
