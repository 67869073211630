import React, { useEffect, useState, Fragment } from "react";
import { translate } from "react-switch-lang";
import { useHistory, useParams } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { toast } from "react-toastify";

import Fetching from "components/fetching/Fetching";
import Breadcrumb from "../../../../layout/breadcrumb";
import SectionOne from "./SectionOne/SectionOne";

import api from "services/api";
import SectionTwo from "./SectionTwo/SectionTwo";

const baseObject = {
	id: "",
	slug: "",
	standard_id: "",
	code: "",
	description: "",
	verification_date: "",
	confirmed: "",
	user_id: "",
	customer_id: "",
};

const VerifyAuditsForm = () => {
	let history = useHistory();
	const { audit_verified_id } = useParams();

	const pageName = audit_verified_id !== "create" ? "Editar Revisión de Norma Auditable" : "Nueva Revisión de Norma Auditable";

	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState({ ...baseObject });
	const [standards, setStandards] = useState([]);
	const [selectStandard, setSelectStandard] = useState(null);

	useEffect(() => {
		setIsFetching(true);
		api.get("/client/audits_verified/detail", { audit_verified_id }).then((response) => {
			if (response.audit_verified) {
				setData(response.audit_verified);
			}
			if (response.standard) {
				setSelectStandard(response.standard);
			}
			setStandards(response.standards);
			setIsFetching(false);
		});

		document.querySelector(".page-header").className = "page-header close_icon";
		document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon ";
	}, [audit_verified_id]);

	const validSave = () => {
		if (!data.standard_id || data.standard_id === "") {
			toast.error("Debe seleccionar una norma para guardar la verificación.");
			return false;
		}
		if (!data.code || data.code === "") {
			toast.error("Debe ingresar un código para guardar la verificación.");
			return false;
		}
		if (!data.verification_date || data.verification_date === "") {
			toast.error("Debe ingresar un fecha de verificación para guardar.");
			return false;
		}
		if (selectStandard.standard_details.length === 0) {
			toast.error("Debe existir al menos un detalle en la norma para guardar.");
			return false;
		}

		return true;
	};

	const handleSave = () => {
		if (validSave()) {
			setIsFetching(true);
			data.details = selectStandard.standard_details;
			api.post("/client/audits_verified/save", data)
				.then((response) => {
					setIsFetching(false);
					toast.success("Revisión de norma guardado correctamente.");
					history.push("/verify_audits");
				})
				.catch((e) => {
					setIsFetching(false);
				});
		}
	};

	const handleConfirm = () => {
		setIsFetching(true);
		api.post("/client/audits_verified/confirm", { id: data.id })
			.then((response) => {
				setIsFetching(false);
				toast.success("Revisión de norma confirmada correctamente.");
				setData({ ...data, confirmed: true });
			})
			.catch((e) => {
				setIsFetching(false);
			});
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<Fragment>
			<Breadcrumb title={pageName} prev_one="Comprobar Norma Auditable" parent="Audithor" />

			<Container fluid={true}>
				{data.id && !data.confirmed && (
					<div style={{ padding: "5px 5px" }}>
						<Button color="success" style={{ background: "red !important" }} onClick={() => handleConfirm()}>
							Confirmar
						</Button>
					</div>
				)}

				<SectionOne data={data} setData={setData} standards={standards} />
				<SectionTwo
					data={data}
					standards={standards}
					selectStandard={selectStandard}
					setSelectStandard={setSelectStandard}
					setIsFetching={setIsFetching}
				/>

				<div style={{ display: "flex", justifyContent: "center", marginBottom: "3em" }}>
					<Button color="secondary" style={{ marginRight: "10px" }} onClick={() => history.push("/verify_audits")}>
						Cancelar
					</Button>
					{!data.confirmed && (
						<Button color="primary" onClick={() => handleSave()}>
							Guardar
						</Button>
					)}
				</div>
			</Container>
		</Fragment>
	);
};

export default translate(VerifyAuditsForm);
