// components for list the documents in corrective action
// Path: src\page\audithor\corrective_actions\form\SectionThree\SectionThree.jsx

import React, { useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

import classes from "../../corrective_action.module.scss";
import FormDocuments from "../FormDocuments";

const SectionThree = ({ data, setData, setIsFetching }) => {
	const [section, setSection] = useState(false);

	return (
		<Card>
			<CardHeader className={`${classes.cardHeader} ${section && classes.active}`} onClick={() => setSection(!section)}>
				SECCION 3: EVIDENCIAS
			</CardHeader>
			{section && (
				<CardBody className={`${classes.cardBody}`}>
					<FormDocuments data={data} setData={setData} setIsFetching={setIsFetching} />
				</CardBody>
			)}
		</Card>
	);
};

export default SectionThree;
