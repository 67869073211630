import moment from "moment";
require("moment/locale/es.js");

export function isValidToken() {
	const token = localStorage.getItem("token");
	const expiration_date = localStorage.getItem("expiration_date");

	const date_expiration = new Date(expiration_date ? expiration_date.replace(/-/g, "/") : null);
	const date_today = new Date();

	if (date_today < date_expiration) {
		if (token) return true;
	}

	return false;
}

export function isValidPermissionPage(pagePermissions, permissions) {
	let realPermission = [];
	pagePermissions.forEach((item) => {
		const search = permissions.filter((p) => p.permission === item.key && p.value);

		if (search.length !== 0) {
			realPermission.push(item);
		}
	});
	return realPermission;
}

export function isValidPermission(permission, permissions) {
	let validate = false;
	if (permissions) {
		const search = permissions.filter((p) => p.permission === permission && p.value);
		if (search.length !== 0) {
			validate = true;
		}
	}

	return validate;
}

export function datetimeToLocale(value) {
	const [date, time] = value.split(" ");
	let [year, month, day] = date.split("-");
	let [hh, mm, ss] = time.split(":");

	const d = new Date(year, month - 1, day, hh, mm, ss);

	year = d.getFullYear();

	month = d.getMonth() + 1;
	month = month < 10 ? `0${month}` : month;

	day = d.getDate();
	day = day < 10 ? `0${day}` : day;

	hh = d.getHours();
	hh = hh < 10 ? `0${hh}` : hh;

	mm = d.getMinutes();
	mm = mm < 10 ? `0${mm}` : mm;

	ss = d.getSeconds();
	ss = ss < 10 ? `0${ss}` : ss;

	return `${day}/${month}/${year} ${hh}:${mm}:${ss}`;
}

export function dateToLocale(value) {
	if (!value || value === "") {
		return "";
	}

	value = value.substr(0, 10);

	let [year, month, day] = value.split("-");
	const d = new Date(year, month - 1, day);

	year = d.getFullYear();

	month = d.getMonth() + 1;
	month = month < 10 ? `0${month}` : month;

	day = d.getDate();
	day = day < 10 ? `0${day}` : day;

	return `${day}-${month}-${year}`;
}

export function integerValidate(e) {
	const whiteList = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace", "Tab", "ArrowRight", "ArrowLeft"];
	if (!whiteList.includes(e.key)) {
		e.preventDefault();
	}
}

export function decimalValidate(e) {
	const whiteList = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "Tab", "ArrowRight", "ArrowLeft"];
	if (!whiteList.includes(e.key)) {
		e.preventDefault();
	}
}

export function formatMiles(number) {
	let result = "";
	let neg = 0;

	if (String(number).slice(0, 1) === "-") {
		number = number * -1;
		neg = 1;
	}

	number = String(number);
	while (number.length > 3) {
		result = "." + number.substr(number.length - 3) + result;
		number = number.substring(0, number.length - 3);
	}

	result = number + result;
	if (neg === 1) {
		result = "-" + result;
	}

	return result;
}

export function formatMilesDecimal(number) {
	if (Number(number) === 0) {
		return 0;
	}
	if (!number || number === "") {
		return "";
	}

	const valor = Number(number);
	const decimal = (valor - parseInt(valor)).toFixed(2).toString().split(".")[1];

	if (Number(decimal) === 0) {
		return formatMiles(parseInt(valor));
	}

	return formatMiles(parseInt(valor)) + "," + decimal;
}

export function dateFormat(date) {
	let day,
		month,
		year = "";

	year = date.getFullYear();

	month = date.getMonth() + 1;
	month = month < 10 ? `0${month}` : month;

	day = date.getDate();
	day = day < 10 ? `0${day}` : day;

	return `${year}-${month}-${day}`;
}

export function dateTimeFormat(date) {
	let day,
		month,
		year = "";

	year = date.getFullYear();

	month = date.getMonth() + 1;
	month = month < 10 ? `0${month}` : month;

	day = date.getDate();
	day = day < 10 ? `0${day}` : day;

	var seconds = date.getSeconds();
	var minutes = date.getMinutes();
	var hour = date.getHours();

	seconds = ("0" + seconds).slice(-2);
	minutes = ("0" + minutes).slice(-2);
	hour = ("0" + hour).slice(-2);

	return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
}

export function datesRange() {
	var date = new Date();

	var first_day = moment(date).startOf("month").startOf("week").format("YYYY-MM-DD HH:mm:ss");
	var last_day = moment(date).endOf("month").endOf("week").format("YYYY-MM-DD HH:mm:ss");

	return {
		first_day,
		last_day,
	};
}

export function isNumeric(str) {
	if (typeof str != "string") return false;
	return !isNaN(str) && !isNaN(parseFloat(str));
}

export function getNow() {
	const date = new Date();
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	const hour = date.getHours();
	const minute = date.getMinutes();
	const second = date.getSeconds();

	return `${day}-${month}-${year} ${hour}:${minute}:${second}`;
}
