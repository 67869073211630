import React from "react";
import { translate } from "react-switch-lang";
import { Col, Label, Input } from "reactstrap";
import Select from "react-select";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { decimalValidate } from "../../../../services/utils";

const Fields = (props) => {
	const { t, data, setData, users } = props;

	const handleUser = (value) => {
		setData({
			...data,
			user_id: value.value,
		});
	};

	const changeDescription = (editor) => {
		const newData = { ...data, observations: editor.getData() };
		setData(newData);
	};

	return (
		<div className="form-tab-detail">
			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="user_id">{t("User")}</Label>
					<Select
						defaultValue={
							data && data.user_id
								? users.filter(
										(c) =>
											Number(c.value) ===
											Number(data.user_id)
								  )
								: ""
						}
						placeholder={<div>{t("Select")}...</div>}
						options={users}
						onChange={(value) => handleUser(value)}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="functionality">{t("Function")}</Label>
					<Input
						className="form-control"
						name="functionality"
						type="text"
						value={
							data && data.functionality ? data.functionality : ""
						}
						onChange={(e) =>
							setData({
								...data,
								functionality: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="area_performance">
						{t("Area Performance")}
					</Label>
					<Input
						className="form-control"
						name="area_performance"
						type="text"
						value={
							data && data.area_performance
								? data.area_performance
								: ""
						}
						onChange={(e) =>
							setData({
								...data,
								area_performance: e.target.value,
							})
						}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="grade_course">{t("Grade Course")}</Label>
					<Input
						className="form-control"
						name="grade_course"
						type="text"
						value={
							data && data.grade_course ? data.grade_course : ""
						}
						onChange={(e) =>
							setData({
								...data,
								grade_course: e.target.value,
							})
						}
						onKeyDown={decimalValidate}
					/>
				</Col>
			</div>

			<div className="form-row">
				<Label htmlFor="grade_course">{t("Observations")}</Label>
				<Col md="12 mb-3">
					<CKEditor
						editor={ClassicEditor}
						data={
							data && data.observations ? data.observations : ""
						}
						onChange={(event, editor) => {
							changeDescription(editor);
						}}
					/>
				</Col>
			</div>
		</div>
	);
};

export default translate(Fields);
