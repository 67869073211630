import React, { useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

import classes from "../../corrective_action.module.scss";
import FormFields from "./FormFields";

const SectionFour = ({ data, setData, users, setIsFetching, userID }) => {
	const [section, setSection] = useState(false);

	return (
		<Card>
			<CardHeader className={`${classes.cardHeader} ${section && classes.active}`} onClick={() => setSection(!section)}>
				SECCION 4: VERIFICACIÓN E IMPLEMENTACIÓN
			</CardHeader>
			{section && (
				<CardBody className={`${classes.cardBody}`}>
					<FormFields data={data} setData={setData} users={users} userID={userID} setIsFetching={setIsFetching} />
				</CardBody>
			)}
		</Card>
	);
};

export default SectionFour;
