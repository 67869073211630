import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";

import { ReactComponent as ArchiveIcon } from "assets/icons/folder.svg";
import { ReactComponent as CheckIcon } from "assets/fonts/feather/check-square.svg";
import { ReactComponent as XIcon } from "assets/fonts/feather/x-square.svg";
import FormDocuments from "../FormDocuments";

const concepts = [
	{ value: "CUMPLE", label: "CUMPLE" },
	{ value: "NO CUMPLE", label: "NO CUMPLE" },
	{ value: "NO APLICA", label: "NO APLICA" },
];

const SectionTwo = ({ data, selectStandard, setSelectStandard, setIsFetching, standards }) => {
	const [showDocuments, setShowDocuments] = useState(false);
	const [indexDetail, setIndexDetail] = useState(null);

	useEffect(() => {
		const standard = standards.find((st) => Number(st.value) === Number(data.standard_id));
		if (standard && !selectStandard) {
			setSelectStandard(standard);
		}
		// eslint-disable-next-line
	}, [data.standard_id]);

	const handleAnswer = (index_detail, index_question, answer) => {
		let newDetails = [...selectStandard.standard_details];
		let newQuestions = [...selectStandard.standard_details[index_detail].questions];

		newQuestions[index_question].answer = answer;

		newDetails[index_detail].questions = newQuestions;
		setSelectStandard({ ...selectStandard, standard_details: newDetails });
	};

	const handleConcept = (index_detail, concept) => {
		let newDetails = [...selectStandard.standard_details];
		newDetails[index_detail].concept = concept;
		setSelectStandard({ ...selectStandard, standard_details: newDetails });
	};

	const handleProcess = (index_detail, process) => {
		let newDetails = [...selectStandard.standard_details];
		newDetails[index_detail].process = process;
		setSelectStandard({ ...selectStandard, standard_details: newDetails });
	};

	const handleArea = (index_detail, area) => {
		let newDetails = [...selectStandard.standard_details];
		newDetails[index_detail].area = area;
		setSelectStandard({ ...selectStandard, standard_details: newDetails });
	};

	const handleObservation = (index_detail, observation) => {
		let newDetails = [...selectStandard.standard_details];
		newDetails[index_detail].observation = observation;
		setSelectStandard({ ...selectStandard, standard_details: newDetails });
	};

	const handleCloseDocument = () => {
		setIndexDetail(null);
		setShowDocuments(false);
	};

	return (
		<div style={{ padding: "10px 0px" }}>
			<Table>
				<Thead>
					<Tr>
						<Th style={{ borderBottom: "2px solid #0e75ba", textAlign: "center", width: "10%" }}>Detalle Norma</Th>
						<Th style={{ borderBottom: "2px solid #0e75ba", textAlign: "center", width: "15%" }}>Preguntas</Th>
						<Th style={{ borderBottom: "2px solid #0e75ba", textAlign: "center", width: "12%" }}>Concepto</Th>
						<Th style={{ borderBottom: "2px solid #0e75ba", textAlign: "center", width: "10%" }}>Proceso</Th>
						<Th style={{ borderBottom: "2px solid #0e75ba", textAlign: "center", width: "10%" }}>Cargo del Área</Th>
						<Th style={{ borderBottom: "2px solid #0e75ba", textAlign: "center", width: "13%" }}>Observación</Th>
						<Th style={{ borderBottom: "2px solid #0e75ba", textAlign: "center", width: "5%" }}>Evidencia</Th>
					</Tr>
				</Thead>
				<Tbody>
					{selectStandard &&
						selectStandard.standard_details &&
						selectStandard.standard_details.map((detail, i) => {
							return (
								<Tr key={i}>
									<Td style={{ borderBottom: "1px solid #0e75ba" }}>{detail.label}</Td>
									<Td style={{ borderBottom: "1px solid #0e75ba" }}>
										{detail.questions.map((question, j) => {
											return (
												<div key={j} style={{ padding: "10px 5px" }}>
													<div>{`${j + 1}. ${question.description}`}</div>
													<div>
														<Input
															className="form-control"
															name="answer"
															type="text"
															value={question.answer && question.answer ? question.answer : ""}
															onChange={(e) => handleAnswer(i, j, e.target.value)}
														/>
													</div>
												</div>
											);
										})}
									</Td>
									<Td
										style={{
											borderBottom: "1px solid #0e75ba",
										}}
									>
										<div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
											<div style={{ width: "80%" }}>
												<Select
													defaultValue={
														detail.concept ? concepts.filter((c) => String(c.value) === String(detail.concept)) : ""
													}
													placeholder={<div>Seleccione...</div>}
													options={concepts}
													onChange={(value) => {
														handleConcept(i, value.value);
													}}
												/>
											</div>
											{detail.concept === "CUMPLE" && <CheckIcon style={{ stroke: "green", width: "20%" }} />}
											{detail.concept === "NO CUMPLE" && <XIcon style={{ stroke: "tomato", width: "20%" }} />}
										</div>
									</Td>
									<Td style={{ borderBottom: "1px solid #0e75ba" }}>
										<Input
											className="form-control"
											name="process"
											type="textarea"
											value={detail.process && detail.process ? detail.process : ""}
											onChange={(e) => handleProcess(i, e.target.value)}
										/>
									</Td>
									<Td style={{ borderBottom: "1px solid #0e75ba" }}>
										<Input
											className="form-control"
											name="area"
											type="textarea"
											value={detail.area && detail.area ? detail.area : ""}
											onChange={(e) => handleArea(i, e.target.value)}
										/>
									</Td>
									<Td style={{ borderBottom: "1px solid #0e75ba" }}>
										<Input
											className="form-control"
											name="observation"
											type="textarea"
											value={detail.observation && detail.observation ? detail.observation : ""}
											onChange={(e) => handleObservation(i, e.target.value)}
										/>
									</Td>
									<Td style={{ borderBottom: "1px solid #0e75ba" }}>
										<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
											<ArchiveIcon
												style={{ width: "2em", height: "2em", cursor: "pointer" }}
												onClick={() => {
													setIndexDetail(i);
													setShowDocuments(true);
												}}
											/>
										</div>
									</Td>
								</Tr>
							);
						})}
				</Tbody>
			</Table>

			<Modal isOpen={showDocuments} toggle={handleCloseDocument} backdrop="static">
				<ModalHeader toggle={handleCloseDocument}>Documentos</ModalHeader>
				<ModalBody>
					<FormDocuments data={selectStandard} setData={setSelectStandard} setIsFetching={setIsFetching} indexDetail={indexDetail} />
				</ModalBody>
			</Modal>
		</div>
	);
};

export default SectionTwo;
