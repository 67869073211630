import React, { useState } from "react";
import { Input } from "reactstrap";

import { ReactComponent as RemoveIcon } from "assets/icons/remove.svg";
import { ReactComponent as CheckIcon } from "assets/fonts/feather/check-square.svg";
import { ReactComponent as XIcon } from "assets/fonts/feather/x-square.svg";

import classes from "../standard.module.scss";
import api from "services/api";
import Fetching from "components/fetching/Fetching";

const FormStandardDetailQuestions = ({ baseQuestions, data, setData, index }) => {
	const [newQuestion, setNewQuestion] = useState("");
	const [createQuestion, setCreateQuestion] = useState(false);
	const [isFetching, setIsFetching] = useState(false);

	const saveQuestion = (text_question) => {
		setIsFetching(true);

		let newQuestions = [{ question: text_question }];
		api.post("/client/standards_detail/questions/save", { standard_detail_id: data.details[index].id, questions: newQuestions }).then(
			(response) => {
				let newDetails = [...data.details];
				newDetails[index].questions = response.questions;
				setNewQuestion("");
				setCreateQuestion(false);
				setIsFetching(false);

				setData({ ...data, details: newDetails });
			}
		);
	};

	const deleteQuestion = (question_id) => {
		setIsFetching(true);
		api.post("/client/standards_detail/questions/delete", { standard_detail_id: data.details[index].id, question: question_id }).then(
			(response) => {
				let newDetails = [...data.details];
				newDetails[index].questions = response.questions;
				setNewQuestion("");
				setCreateQuestion(false);
				setIsFetching(false);
				setData({ ...data, details: newDetails });
			}
		);
	};

	const handleQuestionGeneric = () => {
		let newItems = [...data.details];
		let newQuestions = [];

		baseQuestions.forEach((element) => {
			const in_array = newItems[index].questions.find((que) => que === element.label);
			if (!in_array) {
				newItems[index].questions.push(element.label);
				newQuestions.push({ question: element.label });
			}
		});

		setIsFetching(true);
		api.post("/client/standards_detail/questions/save", { standard_detail_id: data.details[index].id, questions: newQuestions }).then(
			(response) => {
				let newDetails = [...data.details];
				newDetails[index].questions = response.questions;
				setIsFetching(false);
				setData({ ...data, details: newDetails });
			}
		);
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<div className="form-tab-detail">
			{!createQuestion && (
				<div style={{ display: "flex" }}>
					<div className="new_question" style={{ width: "48%" }} onClick={(e) => setCreateQuestion(true)}>
						Nueva Pregunta
					</div>
					<div
						className="new_question"
						style={{ width: "48%", backgroundColor: "#2e8509", marginLeft: "5px" }}
						onClick={(e) => handleQuestionGeneric()}
					>
						Cargar Preguntas Genéricas
					</div>
				</div>
			)}

			{createQuestion && (
				<div className={classes.new_question} style={{ marginTop: "5px", display: "flex" }}>
					<div className={classes.description}>
						<Input
							className="form-control"
							name="title"
							type="text"
							value={newQuestion}
							onChange={(e) => setNewQuestion(e.target.value)}
						/>
					</div>
					<div
						className={`${classes.div_icon} ${classes.check}`}
						onClick={() => {
							saveQuestion(newQuestion);
						}}
					>
						<CheckIcon className={classes.icon} />
					</div>
					<div
						className={`${classes.div_icon} ${classes.times}`}
						onClick={() => {
							setNewQuestion("");
							setCreateQuestion(false);
						}}
					>
						<XIcon className={classes.icon} />
					</div>
				</div>
			)}

			<hr />
			<div className="table-docs">
				{data.details[index] &&
					data.details[index].questions &&
					data.details[index].questions.map((question, i) => {
						return (
							<div className="table-item" key={i}>
								<div className="index">{i + 1}</div>
								<div className="name">{question}</div>
								<div className="actions">
									<RemoveIcon className="file-icons" onClick={() => deleteQuestion(question)} />
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default FormStandardDetailQuestions;
