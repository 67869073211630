import React, { useState } from "react";
import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";

import classes from "../../audit_program.module.scss";

import { ReactComponent as AddIcon } from "../../../../../assets/icons/add.svg";
import ProgramItem from "./ProgramItem";

const SectionFour = ({ data, setData, auditors }) => {
	const [section, setSection] = useState(false);

	const handleAdd = () => {
		let newDetails = [...data.details];
		newDetails.push({
			id: "",
			date_detail: "",
			time_detail: "",
			departament: "",
			auditor_id: "",
		});
		setData({ ...data, details: newDetails });
	};

	return (
		<Card>
			<CardHeader className={`${classes.cardHeader} ${section && classes.active}`} onClick={() => setSection(!section)}>
				SECCION 4: PROGRAMA DE AUDITORIA
			</CardHeader>
			{section && (
				<>
					<CardBody className={classes.cardBody}>
						<div className={classes.header}>
							<div className={classes.header_date}>Fecha</div>
							<div className={classes.header_time}>Hora</div>
							<div className={classes.header_function}>Departamento / Función</div>
							<div className={classes.header_auditor}>Auditor</div>
							<div className={classes.header_action}>Acción</div>
						</div>
						{data &&
							data.details &&
							data.details.map((detail, j) => {
								return <ProgramItem key={j} index={j} data={data} setData={setData} detail={detail} auditors={auditors} />;
							})}
					</CardBody>
					<CardFooter className={classes.cardFooter}>
						<AddIcon className={classes.buttonAdd} onClick={() => handleAdd()} />
					</CardFooter>
				</>
			)}
		</Card>
	);
};

export default SectionFour;
