import React, { useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";

import Fetching from "../../../../components/fetching/Fetching";
import FormFields from "./FormFields";
import FormDocuments from "./FormDocuments";
import FormStandardDetail from "./FormStandardDetail";

import api from "../../../../services/api";
import FormQuestions from "./FormQuestions";

const baseObject = {
	title: "",
	global_standard_id: "",
	description: "",
	details: [],
	documents: [],
};

const StandardForm = (props) => {
	const {
		pageName,
		t,
		callbackAction,
		globalStandards,
		setGlobalStandards,
		globalStandardsDetail,
		setGlobalStandardsDetail,
		questions,
		setQuestions,
	} = props;

	let history = useHistory();
	const { standard_id } = useParams();

	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState({ ...baseObject });
	const [selectTab, setSelectTab] = useState("1");

	const handleClose = () => {
		history.push("/admin/standards");
	};

	const validSave = () => {
		if (!data.title || data.title === "") {
			toast.error(props.t("The title field is required"));
			return false;
		}

		if (!data.global_standard_id || data.global_standard_id === "") {
			toast.error(props.t("The standard field is required"));
			return false;
		}

		if (data.details.length === 0) {
			toast.error(props.t("You must enter at least one detail"));
			return false;
		}

		let validate = true;
		data.details.forEach((detail) => {
			if (!detail.global_standard_detail_id) {
				validate = false;
				toast.error(props.t("You must select a rule detail in all items"));
			}
		});

		return validate;
	};

	const handleSave = async () => {
		let newData = { ...data };
		if (validSave()) {
			setIsFetching(true);
			const response = await callbackAction(newData);
			if (response) {
				setData({ ...baseObject });
			}
			setIsFetching(false);
		}
	};

	useEffect(() => {
		setIsFetching(false);
		if (standard_id && standard_id !== "create") {
			setIsFetching(true);
			api.get("/client/standard", { selected: standard_id }).then((response) => {
				setData(response.standard);
				setIsFetching(false);
			});
		} else {
			setData({ ...baseObject });
		}
		// eslint-disable-next-line
	}, [standard_id]);

	return (
		<Modal isOpen={standard_id ? true : false} toggle={handleClose} size="lg" style={{ maxWidth: "75em", width: "100%" }} backdrop="static">
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={handleClose}>{t(pageName)}</ModalHeader>
					<ModalBody>
						<Nav tabs>
							<NavItem>
								<NavLink className={selectTab === "1" ? "active" : ""} onClick={() => setSelectTab("1")}>
									{t(`Form`)}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className={selectTab === "2" ? "active" : ""} onClick={() => setSelectTab("2")}>
									{t(`Standard Detail`)}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className={selectTab === "3" ? "active" : ""} onClick={() => setSelectTab("3")}>
									Preguntas Genéricas Norma
								</NavLink>
							</NavItem>
							{data && data.id && (
								<NavItem>
									<NavLink className={selectTab === "4" ? "active" : ""} onClick={() => setSelectTab("4")}>
										{t(`Documents`)}
									</NavLink>
								</NavItem>
							)}
						</Nav>
						<TabContent activeTab={selectTab}>
							<TabPane className="fade show" tabId="1">
								<FormFields data={data} setData={setData} globalStandards={globalStandards} setGlobalStandards={setGlobalStandards} />
							</TabPane>
							<TabPane tabId="2">
								<FormStandardDetail
									data={data}
									setData={setData}
									globalStandardsDetail={globalStandardsDetail}
									setGlobalStandardsDetail={setGlobalStandardsDetail}
									questions={questions}
								/>
							</TabPane>
							<TabPane tabId="3">
								<FormQuestions questions={questions} setQuestions={setQuestions} />
							</TabPane>
							<TabPane tabId="4">
								<FormDocuments data={data} setData={setData} setIsFetching={setIsFetching} />
							</TabPane>
						</TabContent>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={handleClose}>
							{t("Cancel")}
						</Button>
						<Button color="primary" onClick={() => handleSave()}>
							{t("Save")}
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default translate(StandardForm);
