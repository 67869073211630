import React from "react";
import { translate } from "react-switch-lang";
import { Input, Col, Label } from "reactstrap";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { isNumeric } from "../../../../services/utils";

const FormFields = (props) => {
	const { t, data, setData, globalStandards, setGlobalStandards } = props;

	const NoOptionsMessage = (props) => {
		return (
			<components.NoOptionsMessage {...props}>
				<span className="custom-css-class">Sin opciones</span>
			</components.NoOptionsMessage>
		);
	};

	const changeDescription = (editor) => {
		const newData = { ...data, description: editor.getData() };
		setData(newData);
	};

	const handleStandard = (opt, meta) => {
		let value = "";
		let newData = { ...data };

		if (opt) {
			value = opt.value;

			const existOption = globalStandards.find(
				(gs) => gs.value === value
			);

			if (!existOption) {
				let newGlobalStandards = [...globalStandards];
				newGlobalStandards.push({ value: value, label: value });
				setGlobalStandards(newGlobalStandards);
			}
		}

		newData.global_standard_id = value;

		setData(newData);
	};

	return (
		<div className="form-tab-detail">
			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="name">{t("Title")}</Label>
					<Input
						className="form-control"
						name="title"
						type="text"
						value={data && data.title}
						onChange={(e) =>
							setData({
								...data,
								title: e.target.value,
							})
						}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="name">{t("Standard")}</Label>

					<CreatableSelect
						value={
							data.global_standard_id &&
							globalStandards.filter(
								(g) =>
									g.value ===
									(isNumeric(data.global_standard_id)
										? Number(data.global_standard_id)
										: data.global_standard_id)
							)
						}
						options={globalStandards}
						components={{ NoOptionsMessage }}
						formatCreateLabel={(value) => {
							return `Crear ${value}`;
						}}
						placeholder={<div>{t("Select")}...</div>}
						isClearable
						onChange={(opt, meta) => {
							handleStandard(opt, meta);
						}}
					/>
				</Col>

				<Col md="12 mb-3">
					<Label htmlFor="description">{t("Description")}</Label>

					<CKEditor
						editor={ClassicEditor}
						data={data && data.description ? data.description : ""}
						onChange={(event, editor) => {
							changeDescription(editor);
						}}
					/>
				</Col>
			</div>
		</div>
	);
};

export default translate(FormFields);
