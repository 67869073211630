// dashbaord
import Dashboard from "../components/dashboard";
import Customer from "../page/maintainers/customer/Customer";
import Role from "../page/maintainers/role/Role";
import Screen from "../page/maintainers/screen/Screen";
import ScreenOption from "../page/maintainers/screen_option/ScreenOption";
import User from "../page/maintainers/user/User";
import Standard from "../page/maintainers/standards/Standard";
import GlobalStandard from "../page/maintainers/global_standards/GlobalStandard";
import GlobalStandardDetail from "../page/maintainers/global_standards_detail/GlobalStandardDetail";
import Auditor from "../page/audithor/auditors/Auditor";
import AuditSchedule from "../page/audithor/audit_schedule/AuditSchedule";
import AuditProgram from "../page/audithor/audit_program/AuditProgram";
import AuditProgramForm from "../page/audithor/audit_program/form/AuditProgramForm";
import VerifyAudits from "page/audithor/verify_audits/VerifyAudits";
import VerifyAuditsForm from "page/audithor/verify_audits/form/VerifyAuditsForm";
import CorrectiveAction from "../page/audithor/corrective_actions/CorrectiveAction";
import CorrectiveActionForm from "page/audithor/corrective_actions/form/CorrectiveActionForm";

export const routes = [
	{
		path: `${process.env.PUBLIC_URL}/`,
		Component: Dashboard,
	},
	{
		path: `${process.env.PUBLIC_URL}/dashboard`,
		Component: Dashboard,
	},
	{
		path: `${process.env.PUBLIC_URL}/admin/customers/:customer_id?`,
		Component: Customer,
	},
	{
		path: `${process.env.PUBLIC_URL}/admin/screens`,
		Component: Screen,
	},
	{
		path: `${process.env.PUBLIC_URL}/admin/screens_option`,
		Component: ScreenOption,
	},
	{
		path: `${process.env.PUBLIC_URL}/admin/roles/:role_id?`,
		Component: Role,
	},
	{
		path: `${process.env.PUBLIC_URL}/admin/users/:user_id?`,
		Component: User,
	},
	{
		path: `${process.env.PUBLIC_URL}/admin/global_standards/:global_standard_id?`,
		Component: GlobalStandard,
	},
	{
		path: `${process.env.PUBLIC_URL}/admin/global_standards_detail/:global_standard_detail_id?`,
		Component: GlobalStandardDetail,
	},
	{
		path: `${process.env.PUBLIC_URL}/admin/standards/:standard_id?`,
		Component: Standard,
	},
	{
		path: `${process.env.PUBLIC_URL}/auditors/:auditor_id?`,
		Component: Auditor,
	},
	{
		path: `${process.env.PUBLIC_URL}/audit_schedule/:audit_schedule_id?`,
		Component: AuditSchedule,
	},
	{
		path: `${process.env.PUBLIC_URL}/audit_program`,
		Component: AuditProgram,
	},
	{
		path: `${process.env.PUBLIC_URL}/audit_program/:audit_program_id?`,
		Component: AuditProgramForm,
	},
	{
		path: `${process.env.PUBLIC_URL}/verify_audits`,
		Component: VerifyAudits,
	},
	{
		path: `${process.env.PUBLIC_URL}/verify_audits/:audit_verified_id?`,
		Component: VerifyAuditsForm,
	},
	{
		path: `${process.env.PUBLIC_URL}/audit_report`,
		Component: AuditProgram,
	},
	{
		path: `${process.env.PUBLIC_URL}/audit_report/:audit_program_id?`,
		Component: AuditProgramForm,
	},
	{
		path: `${process.env.PUBLIC_URL}/corrective_actions`,
		Component: CorrectiveAction,
	},
	{
		path: `${process.env.PUBLIC_URL}/corrective_actions/:corrective_action_id?`,
		Component: CorrectiveActionForm,
	},
];
