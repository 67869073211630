import React, { useState } from "react";
import { Col, Label, Input, Table } from "reactstrap";
import { toast } from "react-toastify";

import classes from "./customerForm.module.scss";

import Fetching from "../../../../components/fetching/Fetching";
import api from "../../../../services/api";

import {
	integerValidate,
	dateToLocale,
	formatMiles,
} from "../../../../services/utils";

const FormPeriods = ({ data, setData, t }) => {
	const [isFetching, setIsFetching] = useState(false);
	const [initialDate, setInitialDate] = useState("");
	const [finalDate, setFinalDate] = useState("");
	const [value, setValue] = useState("");

	const createPeriod = () => {
		if (!initialDate) {
			toast.success(t("You must enter a start date to continue"));
			return;
		}
		if (!finalDate) {
			toast.success(t("You must enter a end date to continue"));
			return;
		}
		if (!value) {
			toast.success(t("You must enter a price to continue"));
			return;
		}

		const newPeriod = {
			initial_date: initialDate,
			final_date: finalDate,
			price: value,
		};

		let newPeriods = [...data.periods];
		newPeriods.push(newPeriod);

		setIsFetching(true);
		api.post("/admin/customers_save_period", { data, newPeriod }).then(
			(response) => {
				setInitialDate("");
				setFinalDate("");
				setValue("");
				setIsFetching(false);
				toast.success(t("Period saved successfully"));

				setData(response.customer);
			}
		);
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<div style={{ marginTop: "10px" }}>
			<div className="form-row" style={{ marginTop: "10px" }}>
				<Col md="4 mb-3">
					<Label htmlFor="initialDate">{t("Initial Date")}</Label>
					<Input
						className="form-control"
						name="initialDate"
						type="date"
						value={initialDate && initialDate}
						onChange={(e) => setInitialDate(e.target.value)}
					/>
				</Col>
				<Col md="4 mb-3">
					<Label htmlFor="finalDate">{t("Finish Date")}</Label>
					<Input
						className="form-control"
						name="finalDate"
						type="date"
						value={finalDate && finalDate}
						onChange={(e) => setFinalDate(e.target.value)}
					/>
				</Col>

				<Col md="4 mb-3">
					<Label htmlFor="value">{t("Value")}</Label>
					<Input
						className="form-control"
						name="value"
						type="text"
						value={value && value}
						onChange={(e) => setValue(e.target.value)}
						onKeyDown={integerValidate}
					/>
				</Col>
			</div>

			<div className={classes.divActivate}>
				<div
					className={classes.activeButtom}
					onClick={() => createPeriod()}
				>
					{t("Create Period")}
				</div>
			</div>

			<Table className={classes.tablePeriods}>
				<thead>
					<tr>
						<th>Fecha de inicio</th>
						<th>Fecha de termino</th>
						<th>Valor</th>
						<th>Documento</th>
					</tr>
				</thead>
				<tbody>
					{data &&
						data.periods &&
						data.periods.map((period, i) => {
							return (
								<tr key={i}>
									<td>{dateToLocale(period.initial_date)}</td>
									<td>{dateToLocale(period.final_date)}</td>
									<td>{formatMiles(period.price)}</td>
									<td></td>
								</tr>
							);
						})}
				</tbody>
			</Table>
		</div>
	);
};

export default FormPeriods;
