import React, { useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

import FormFields from "./FormFields";

import classes from "../../verify_audits.module.scss";

const SectionOne = ({ data, setData, standards }) => {
	const [section, setSection] = useState(true);

	return (
		<Card>
			<CardHeader className={`${classes.cardHeader} ${section && classes.active}`} onClick={() => setSection(!section)}>
				FORMULARIO
			</CardHeader>
			{section && (
				<CardBody className={`${classes.cardBody}`}>
					<FormFields data={data} setData={setData} standards={standards} />
				</CardBody>
			)}
		</Card>
	);
};

export default SectionOne;
