import React, { useState, useEffect } from "react";
import { translate } from "react-switch-lang";
import { Col, Label, Input } from "reactstrap";
import Select from "react-select";

const Fields = (props) => {
	const { t, data, setData, countries, allCommunes, customers } = props;
	const customer_id = localStorage.getItem("customer_id");

	const [communes, setCommunes] = useState([]);

	const handleCountry = (value) => {
		const filter = allCommunes.filter((c) => Number(c.country_id) === Number(value.value));
		setData({
			...data,
			country_id: value.value,
		});
		setCommunes(filter);
	};

	const handleCommune = (value) => {
		setData({
			...data,
			commune_id: value.value,
		});
	};

	const handleCustomer = (value) => {
		setData({
			...data,
			customer_id: value.value,
		});
	};

	useEffect(() => {
		if (data && data.country_id) {
			setCommunes(allCommunes.filter((c) => Number(c.country_id) === Number(data.country_id)));
		}
		// eslint-disable-next-line
	}, [data]);

	return (
		<div className="form-tab-detail">
			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="name">{t("Name")}</Label>
					<Input
						className="form-control"
						name="name"
						type="text"
						value={data && data.name ? data.name : ""}
						onChange={(e) =>
							setData({
								...data,
								name: e.target.value,
							})
						}
					/>
				</Col>
				<Col md="6 mb-3">
					<Label htmlFor="email">{t("Email")}</Label>
					<Input
						className="form-control"
						name="email"
						type="text"
						value={data && data.email ? data.email : ""}
						onChange={(e) =>
							setData({
								...data,
								email: e.target.value,
								username: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			{Number(customer_id) === 1 && (
				<div className="form-row">
					<Col md="6 mb-3">
						<Label htmlFor="customer_id">{t("Customer")}</Label>
						<Select
							defaultValue={data && data.customer_id ? customers.filter((c) => Number(c.value) === Number(data.customer_id)) : ""}
							placeholder={<div>{t("Select")}...</div>}
							options={customers}
							onChange={(value) => handleCustomer(value)}
						/>
					</Col>
				</div>
			)}

			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="password">{t("Password")}</Label>
					<Input
						className="form-control"
						name="password"
						type="password"
						value={data && data.password ? data.password : ""}
						onChange={(e) =>
							setData({
								...data,
								password: e.target.value,
							})
						}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="confirm_password">{t("Confirm Password")}</Label>
					<Input
						className="form-control"
						name="confirm_password"
						type="password"
						value={data && data.confirm_password ? data.confirm_password : ""}
						onChange={(e) =>
							setData({
								...data,
								confirm_password: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="country_id">{t("Country")}</Label>
					<Select
						defaultValue={data && data.country_id ? countries.filter((c) => Number(c.value) === Number(data.country_id)) : ""}
						placeholder={<div>{t("Select")}...</div>}
						options={countries}
						onChange={(value) => handleCountry(value)}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="commune_id">{t("Commune")}</Label>
					<Select
						defaultValue={data && data.commune_id ? allCommunes.filter((c) => Number(c.value) === Number(data.commune_id)) : ""}
						placeholder={<div>{t("Select")}...</div>}
						options={communes}
						onChange={(value) => handleCommune(value)}
					/>
				</Col>
			</div>

			<div className="form-row">
				<Col md="12 mb-3">
					<Label htmlFor="address">{t("Address")}</Label>
					<Input
						className="form-control"
						name="address"
						type="text"
						value={data && data.address ? data.address : ""}
						onChange={(e) =>
							setData({
								...data,
								address: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			<div className="form-row">
				<Col md="6 mb-3">
					<Label style={{ marginRight: "1em" }} htmlFor="active">
						{t("Active")}
					</Label>
					<Input
						className="checkbox_animated"
						id={`user_active`}
						type="checkbox"
						checked={data && data.active ? data.active : false}
						onChange={(e) => {
							setData({
								...data,
								active: e.target.checked,
							});
						}}
					/>
				</Col>
			</div>
		</div>
	);
};

export default translate(Fields);
