import React, { useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from "reactstrap";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";

import Fetching from "../../../../components/fetching/Fetching";
import FormFields from "./FormFields";
import FormPermissions from "./FormPermissions";

import api from "../../../../services/api";

const baseObject = {
	description: "",
	name: "",
	customer_id: "",
	permissions: [],
};

const ScreenOptionForm = (props) => {
	let history = useHistory();
	const { role_id } = useParams();

	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState({ ...baseObject });
	const [BasicTab, setBasicTab] = useState("1");

	const { pageName, t, customers, callbackAction } = props;

	const handleClose = () => {
		history.push("/admin/roles");
	};

	const validSave = () => {
		if (!data.name || data.name === "") {
			toast.error(props.t("The name field is required"));
			return false;
		}
		return true;
	};

	const handleSave = () => {
		setIsFetching(true);
		if (validSave()) {
			callbackAction(data, afterSave);
		}
	};

	const afterSave = () => {
		setData({ ...baseObject });
		setIsFetching(false);
	};

	useEffect(() => {
		setIsFetching(false);
		if (role_id) {
			setIsFetching(true);
			api.get("/admin/role", { selected: role_id }).then((response) => {
				setData(response.role);
				setIsFetching(false);
			});
		} else {
			setData({ ...baseObject });
		}
	}, [role_id]);

	return (
		<Modal
			isOpen={role_id ? true : false}
			toggle={handleClose}
			size="lg"
			backdrop="static"
		>
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={handleClose}>
						{t(pageName)}
					</ModalHeader>
					<ModalBody>
						<Nav tabs>
							<NavItem>
								<NavLink
									className={BasicTab === "1" ? "active" : ""}
									onClick={() => setBasicTab("1")}
								>
									{t(`Form`)}
								</NavLink>
							</NavItem>

							<NavItem>
								<NavLink
									className={BasicTab === "2" ? "active" : ""}
									onClick={() => setBasicTab("2")}
								>
									{t(`Permissions`)}
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={BasicTab}>
							<TabPane className="fade show" tabId="1">
								<FormFields
									data={data}
									setData={setData}
									customers={customers}
								/>
							</TabPane>
							<TabPane tabId="2">
								<FormPermissions
									data={data}
									setData={setData}
								/>
							</TabPane>
						</TabContent>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={handleClose}>
							{t("Cancel")}
						</Button>
						<Button color="secondary" onClick={() => handleSave()}>
							{t("Save")}
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default translate(ScreenOptionForm);
