export class ConfigDB {
	static data = {
		settings: {
			layout_type: "ltr",
			sidebar: {
				type: "compact-wrapper",
			},
			sidebar_setting: "default-sidebar",
		},
		color: {
			primary_color: "#b4c3e6",
			secondary_color: "#0e75ba",
			mix_background_layout: "dark-sidebar",
		},
		router_animation: "fadeIn",
	};
}
export default ConfigDB;
