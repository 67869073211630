import React from "react";
import { translate } from "react-switch-lang";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { ReactComponent as EditIcon } from "../../../../assets/icons/editar.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/basura.svg";
import { dateToLocale, isValidPermission } from "../../../../services/utils";
import { Badge } from "reactstrap";

const AuditProgramTable = (props) => {
	const { editElement, deleteElement, data, permissions } = props;

	return (
		<div className="base-div">
			<Table className="base-table">
				<Thead>
					<Tr>
						<Th>Estado</Th>
						{window.location.pathname === "/audit_program" && (
							<>
								<Th>Norma</Th>
								<Th>Detalle de la Norma</Th>
								<Th style={{ width: "7%" }}>Fecha Inicio</Th>
								<Th style={{ width: "7%" }}>Fecha Fin</Th>
							</>
						)}
						{window.location.pathname === "/audit_report" && (
							<>
								<Th>Sección</Th>
								<Th>Área</Th>
							</>
						)}
						<Th>Usuario</Th>
						<Th>Auditores</Th>
						<Th>Objetivo</Th>
						<Th>Alcance</Th>
						<Th>Acciones</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, i) => {
						return (
							<Tr key={i}>
								<Td>
									{item && item.confirmed ? (
										<Badge color={"success"}>Confirmado</Badge>
									) : (
										<Badge color={"secondary"}>Ingresado</Badge>
									)}
								</Td>

								{window.location.pathname === "/audit_program" && (
									<>
										<Td>{item && item.audit_schedule && item.audit_schedule.standard && item.audit_schedule.standard.title}</Td>
										<Td>
											<ul>
												{item &&
													item.audit_schedule &&
													item.audit_schedule.standard_details &&
													item.audit_schedule.standard_details.map((detail, j) => {
														return <li key={j}>{detail.description}</li>;
													})}
											</ul>
										</Td>
										<Td style={{ textAlign: "center" }}>
											{item && item.audit_schedule && dateToLocale(item.audit_schedule.start_date)}
										</Td>
										<Td style={{ textAlign: "center" }}>
											{item && item.audit_schedule && dateToLocale(item.audit_schedule.end_date)}
										</Td>
									</>
								)}
								{window.location.pathname === "/audit_report" && (
									<>
										<Td>{item.section ? item.section : ""}</Td>
										<Td>{item.area ? item.area : ""}</Td>
									</>
								)}

								<Td>{item && item.user && item.user.label}</Td>
								<Td>
									<ul>
										{item &&
											item.auditors &&
											item.auditors.map((auditor, j) => {
												return <li key={j}>{auditor.user ? auditor.user.name : ""}</li>;
											})}
									</ul>
								</Td>
								<Td>
									{item ? (
										<div
											dangerouslySetInnerHTML={{
												__html: item.objective,
											}}
										></div>
									) : (
										""
									)}
								</Td>
								<Td>
									{item ? (
										<div
											dangerouslySetInnerHTML={{
												__html: item.scope,
											}}
										></div>
									) : (
										""
									)}
								</Td>
								<Td>
									<div>
										{isValidPermission("edit", permissions) && (
											<span className="table-actions" onClick={() => editElement(item)}>
												<EditIcon className="icon-edit" />
											</span>
										)}
										{isValidPermission("delete", permissions) && (
											<span className="table-actions icon trash" onClick={() => deleteElement(item)}>
												<DeleteIcon className="icon-delete" />
											</span>
										)}
									</div>
								</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</div>
	);
};

export default translate(AuditProgramTable);
