import React from "react";
import { translate } from "react-switch-lang";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { ReactComponent as EditIcon } from "../../../../assets/icons/editar.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/basura.svg";
import { isValidPermission, dateToLocale } from "../../../../services/utils";
import { Badge } from "reactstrap";

const UserTable = (props) => {
	const { editElement, deleteElement, data, permissions } = props;
	const customer_id = localStorage.getItem("customer_id");

	return (
		<div className="base-div">
			<Table className="base-table">
				<Thead>
					<Tr>
						<Th>Nombre</Th>
						<Th>Email</Th>
						<Th>Comuna</Th>
						{Number(customer_id) === 1 && <Th>Cliente</Th>}
						<Th>Fecha de Ingreso</Th>
						<Th>Roles</Th>
						<Th>Estado</Th>
						<Th>Acciones</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, i) => {
						return (
							<Tr key={i}>
								<Td>{item.name}</Td>
								<Td>{item.email}</Td>
								<Td>{item.commune && item.commune.name}</Td>
								{Number(customer_id) === 1 && <Td>{item.customer && item.customer.name}</Td>}
								<Td>{dateToLocale(item.created_at)}</Td>
								<Td>
									{item.roles.length !== 0 && (
										<ul>
											{item.roles.map((role, j) => {
												return <li key={j}>{`${j + 1}. ${role.name}`}</li>;
											})}
										</ul>
									)}
								</Td>
								<Td style={{ textAlign: "center" }}>
									{item.active ? <Badge color="success">Activo</Badge> : <Badge color="danger">Inactivo</Badge>}
								</Td>
								<Td>
									<div>
										{isValidPermission("edit", permissions) && (
											<span className="table-actions" onClick={() => editElement(item)}>
												<EditIcon className="icon-edit" />
											</span>
										)}
										{isValidPermission("delete", permissions) && (
											<span className="table-actions icon trash" onClick={() => deleteElement(item)}>
												<DeleteIcon className="icon-delete" />
											</span>
										)}
									</div>
								</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</div>
	);
};

export default translate(UserTable);
