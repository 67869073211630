import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";

import Breadcrumb from "../../../layout/breadcrumb";
import Search from "../../../components/list/Search";

import {
	datesRange,
	dateFormat,
	dateToLocale,
	dateTimeFormat,
} from "../../../services/utils";

import api from "../../../services/api";
import AuditScheduleForm from "./form/AuditScheduleForm";
import Fetching from "../../../components/fetching/Fetching";
import { translate } from "react-switch-lang";

require("moment/locale/es.js");
const localizer = momentLocalizer(moment);
const messages = {
	allDay: "Todos los días",
	previous: "Anterior",
	next: "Siguiente",
	today: "Hoy",
	month: "Mes",
	week: "Semana",
	day: "Día",
	agenda: "Agenda",
	date: "Fecha",
	time: "Hora",
	event: "Evento",
	sunda: "Dom",
	showMore: (total) => `+${total} más...`,
};

const eventStyleGetter = (event, start, end, isSelected) => {
	return {
		style: {
			backgroundColor: "#3533cb !important",
			opacity: 0.8,
			color: "white",
			border: "0px",
			display: "block",
			fontWeight: 500,
			borderRadius: "5px",
		},
	};
};

const AuditSchedule = ({ t }) => {
	let history = useHistory();

	const pageName = "Audit Schedule";

	const [isFetching, setIsFetching] = useState(true);
	const [search, setSearch] = useState("");
	const [mainSearch, setMainSearch] = useState("");
	const [permissions, setPermissions] = useState([]);

	const [events, setEvents] = useState([]);
	const [standards, setStandards] = useState([]);
	const [auditors, setAuditors] = useState([]);

	const [baseDate, setBaseDate] = useState("");

	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");

	useEffect(() => {
		if (!startDate || !endDate) {
			const currentDate = new Date();
			const dates = datesRange();

			setBaseDate(
				`${currentDate.getFullYear()}-${
					currentDate.getMonth() + 1 < 10
						? `0${currentDate.getMonth() + 1}`
						: `${currentDate.getMonth() + 1}`
				}-${currentDate.getDate() + 1}`
			);
			setStartDate(dates.first_day);
			setEndDate(dates.last_day);
		} else {
			setIsFetching(true);
			api.get("/client/audits_schedule", {
				startDate,
				endDate,
				baseDate,
				search,
			}).then((response) => {
				updateData(response);
			});
		}
	}, [startDate, endDate, baseDate, search]);

	const updateData = (response) => {
		const { audits_schedule, permissions, standards, auditors } = response;

		let newAuditsSchedule = [...audits_schedule];
		newAuditsSchedule.forEach((item, i) => {
			newAuditsSchedule[i].startDate = new Date(item.start_date);
			newAuditsSchedule[i].endDate = new Date(item.end_date);
			newAuditsSchedule[
				i
			].title = `${item.standard.title} (${item.standard.name})`;
			newAuditsSchedule[i].allDay = false;
		});

		setEvents(newAuditsSchedule);

		setPermissions(permissions);
		setStandards(standards);
		setAuditors(auditors);
		setIsFetching(false);
	};

	const showEventForm = (eventSelect) => {
		history.push("/audit_schedule/" + eventSelect.slug);
	};

	const onNavigate = (date, view) => {
		setBaseDate(dateFormat(date));

		const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
		const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

		setStartDate(dateTimeFormat(firstDay));
		setEndDate(dateTimeFormat(lastDay));
	};

	const delayedQuery = useCallback(
		_.debounce((v) => {
			setSearch(v);
		}, 500),
		[]
	);

	const setDelaySearch = (value) => {
		setMainSearch(value);
		delayedQuery(value);
	};

	const createElement = () => {
		history.push("/audit_schedule/create");
	};

	const handleSave = (data) => {
		return api
			.post("/client/audits_schedule_save", {
				...data,
				startDate: startDate,
				endDate: endDate,
			})
			.then((response) => {
				updateData(response);
				toast.success(t("Record saved successfully"));
				history.push("/audit_schedule");

				return true;
			})
			.catch((error) => {
				return false;
			});
	};

	const EventComponent = (event) => {
		return (
			<div
				style={{
					backgroundColor:
						event.event.type_audit === "INTERNA"
							? "#009688"
							: "#ff5722",
					padding: "5px",
					borderRadius: "10px",
				}}
			>
				<span style={{ color: "black" }}>{event.event.title}</span>
				<ul>
					{event.event &&
						event.event.standard_details &&
						event.event.standard_details.map((detail, i) => {
							return (
								<li key={i}>
									<small style={{ fontStyle: "italic" }}>
										- {detail.label}
									</small>
								</li>
							);
						})}
				</ul>
				<small>
					{`desde ${dateToLocale(
						event.event.start_date
					)} hasta ${dateToLocale(event.event.end_date)}`}
				</small>
			</div>
		);
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<Fragment>
			<Breadcrumb title={pageName} parent="Maintainers" />

			<Container fluid={true}>
				<Row>
					<Search
						search={mainSearch}
						setSearch={setDelaySearch}
						permissions={permissions}
						handleCreate={createElement}
					/>

					<Col sm="12">
						<Card>
							<CardBody>
								<Calendar
									localizer={localizer}
									messages={messages}
									events={events}
									components={{
										event: EventComponent,
									}}
									startAccessor="startDate"
									endAccessor="endDate"
									scrollToTime={new Date(1970, 1, 1, 6)}
									defaultDate={new Date(baseDate)}
									date={new Date(baseDate)}
									views={["month"]}
									onSelectEvent={(event) =>
										showEventForm(event)
									}
									eventPropGetter={eventStyleGetter}
									onNavigate={onNavigate}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>

				<AuditScheduleForm
					pageName={pageName}
					standards={standards}
					auditors={auditors}
					callbackAction={handleSave}
				/>
			</Container>
		</Fragment>
	);
};

export default translate(AuditSchedule);
