import React, { useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from "reactstrap";
import { toast } from "react-toastify";

import Fetching from "../../../../components/fetching/Fetching";
import FormFields from "./FormFields";
import FormScreens from "./FormScreens";

import api from "../../../../services/api";

const baseObject = {
	description: "",
	icon: "",
	indice: "",
	label: "",
	path: "",
	permission_screens: [],
};

const ScreenOptionForm = (props) => {
	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState(baseObject);
	const [BasicTab, setBasicTab] = useState("1");

	const { pageName, selected, form, setForm, t, callbackAction } = props;

	const handleClose = () => {
		setForm(false);
	};

	const handleSave = () => {
		if (validSave()) {
			callbackAction(data);
			setData(baseObject);
		}
	};

	const validSave = () => {
		if (!data.label || data.label === "") {
			toast.error(props.t("The label field is required"));
			return false;
		}
		return true;
	};

	useEffect(() => {
		setIsFetching(false);
		if (selected) {
			setIsFetching(true);
			api.get("/admin/screen_option", { selected }).then((response) => {
				setData(response.screen_option);
				setIsFetching(false);
			});
		} else {
			setData(baseObject);
		}
	}, [selected]);

	return (
		<Modal isOpen={form} toggle={handleClose} size="lg" backdrop="static">
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={handleClose}>
						{t(pageName)}
					</ModalHeader>
					<ModalBody>
						<Nav tabs>
							<NavItem>
								<NavLink
									className={BasicTab === "1" ? "active" : ""}
									onClick={() => setBasicTab("1")}
								>
									{t(`Form`)}
								</NavLink>
							</NavItem>

							<NavItem>
								<NavLink
									className={BasicTab === "2" ? "active" : ""}
									onClick={() => setBasicTab("2")}
								>
									{t(`Screens`)}
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={BasicTab}>
							<TabPane className="fade show" tabId="1">
								<FormFields data={data} setData={setData} />
							</TabPane>
							<TabPane tabId="2">
								<FormScreens data={data} setData={setData} />
							</TabPane>
						</TabContent>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={handleClose}>
							{t("Cancel")}
						</Button>
						<Button color="primary" onClick={() => handleSave()}>
							{t("Save")}
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default translate(ScreenOptionForm);
