import React, { useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from "reactstrap";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";

import Fetching from "../../../../components/fetching/Fetching";
import FormFields from "./FormFields";
import FormDocuments from "./FormDocuments";

import api from "../../../../services/api";

const baseObject = {
	id: "",
	standard_id: "",
	start_date: "",
	end_date: "",
	type_audit: "",
	standard_details: [],
	auditors: [],
	documents: [],
};

const AuditScheduleForm = (props) => {
	let history = useHistory();
	const { audit_schedule_id } = useParams();

	const { pageName, t, standards, auditors, callbackAction } = props;

	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState({ ...baseObject });
	const [selectTab, setSelectTab] = useState("1");

	const handleClose = () => {
		setSelectTab("1");
		history.push("/audit_schedule");
	};

	const validSave = () => {
		if (!data.standard_id || data.standard_id === "") {
			toast.error(props.t("The standard field is required"));
			return false;
		}

		if (data.standard_details.length === 0) {
			toast.error(
				props.t(
					"You must select at least one detail of the auditable standard"
				)
			);
			return false;
		}

		if (!data.start_date || data.start_date === "") {
			toast.error(props.t("The start date field is required"));
			return false;
		}

		if (!data.end_date || data.end_date === "") {
			toast.error(props.t("The end date field is required"));
			return false;
		}

		if (data.start_date > data.end_date) {
			toast.error(
				"La fecha de inicio no puede ser superior a la fecha de finalización"
			);
			return false;
		}

		if (!data.type_audit || data.type_audit === "") {
			toast.error(props.t("The type audit field is required"));
			return false;
		}

		if (data.auditors.length === 0) {
			toast.error(props.t("You must select at least one auditor"));
			return false;
		}

		return true;
	};

	const handleSave = async () => {
		if (validSave()) {
			setIsFetching(true);
			const response = await callbackAction({
				...data,
			});
			if (response) {
				setData({ ...baseObject });
			}
			setSelectTab("1");
			setIsFetching(false);
		}
	};

	useEffect(() => {
		setIsFetching(false);
		if (audit_schedule_id && audit_schedule_id !== "create") {
			setIsFetching(true);
			api.get("/client/audit_schedule", { selected: audit_schedule_id })
				.then((response) => {
					setData(response.audit_schedule);

					setIsFetching(false);
				})
				.catch((e) => console.error(e));
		} else {
			setData({ ...baseObject });
		}
		// eslint-disable-next-line
	}, [audit_schedule_id]);

	return (
		<Modal
			isOpen={audit_schedule_id ? true : false}
			toggle={handleClose}
			size="lg"
			backdrop="static"
		>
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={handleClose}>
						{t(pageName)}
					</ModalHeader>
					<ModalBody>
						<Nav tabs>
							<NavItem>
								<NavLink
									className={
										selectTab === "1" ? "active" : ""
									}
									onClick={() => setSelectTab("1")}
								>
									{t(`Form`)}
								</NavLink>
							</NavItem>
							{data.id && (
								<NavItem>
									<NavLink
										className={
											selectTab === "2" ? "active" : ""
										}
										onClick={() => setSelectTab("2")}
									>
										{t(`Documents`)}
									</NavLink>
								</NavItem>
							)}
						</Nav>
						<TabContent activeTab={selectTab}>
							<TabPane className="fade show" tabId="1">
								<FormFields
									data={data}
									setData={setData}
									standards={standards}
									auditors={auditors}
									setIsFetching={setIsFetching}
								/>
							</TabPane>
							<TabPane tabId="2">
								<FormDocuments
									data={data}
									setData={setData}
									setIsFetching={setIsFetching}
								/>
							</TabPane>
						</TabContent>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={handleClose}>
							{t("Cancel")}
						</Button>
						<Button color="primary" onClick={() => handleSave()}>
							{t("Save")}
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default translate(AuditScheduleForm);
