import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Container, Row } from "reactstrap";
import { translate } from "react-switch-lang";
import _ from "lodash";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import Breadcrumb from "../../../layout/breadcrumb";
import Paginator from "../../../components/list/Paginator";
import Search from "../../../components/list/Search";
import GlobalStandardDetailTable from "./table/GlobalStandardDetailTable";
import GlobalStandardDetailForm from "./form/GlobalStandardDetailForm";
import Fetching from "../../../components/fetching/Fetching";
import Confirm from "../../../components/confirm/Confirm";

import api from "../../../services/api";

const GlobalStandardDetail = (props) => {
	let history = useHistory();

	const pageName = "Global Standards Detail";
	const [isFetching, setIsFetching] = useState(true);
	const [search, setSearch] = useState("");
	const [mainSearch, setMainSearch] = useState("");
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [permissions, setPermissions] = useState([]);
	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");
	const [total, setTotal] = useState("");
	const [lastPage, setLastPage] = useState("");

	const [selected, setSelected] = useState("");
	const [modal, setModal] = useState(false);

	useEffect(() => {
		const form = {
			page,
			search,
		};

		api.get("/admin/global_standards_detail", form).then((response) => {
			updateData(response);
		});
		// eslint-disable-next-line
	}, [page, search]);

	const updateData = (response) => {
		const { global_standards_detail, permissions } = response;

		setPermissions(permissions);
		setData(global_standards_detail.data);
		setFrom(global_standards_detail.from);
		setTo(global_standards_detail.to);
		setTotal(global_standards_detail.total);
		setLastPage(global_standards_detail.last_page);

		setIsFetching(false);
	};

	const delayedQuery = useCallback(
		_.debounce((v) => {
			setSearch(v);
		}, 500),
		[]
	);

	const setDelaySearch = (value) => {
		setMainSearch(value);
		delayedQuery(value);
	};

	// Form
	const createElement = () => {
		history.push("/admin/global_standards_detail/create");
	};

	const editElement = (item) => {
		history.push("/admin/global_standards_detail/" + item.slug);
	};

	const handleEdit = async (data) => {
		try {
			const response = await api.post(
				"/admin/global_standards_detail_save",
				data
			);

			updateData(response);
			history.push("/admin/global_standards_detail");
			toast.success(props.t("Record saved successfully"));
			return true;
		} catch (error) {
			return false;
		}
	};

	const deleteElement = (item) => {
		setSelected(item.id);
		setModal(true);
	};

	const handleDelete = () => {
		api.post("/admin/global_standards_detail_delete", { selected }).then(
			(response) => {
				updateData(response);
				setSelected("");

				toast.success(props.t("Record deleted successfully"));
			}
		);
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<Fragment>
			<Breadcrumb title={pageName} parent="Maintainers" />
			<Container fluid={true}>
				<Row>
					<Search
						search={mainSearch}
						setSearch={setDelaySearch}
						to={to}
						from={from}
						total={total}
						permissions={permissions}
						handleCreate={createElement}
					/>

					<GlobalStandardDetailTable
						editElement={editElement}
						deleteElement={deleteElement}
						data={data}
						permissions={permissions}
					/>

					<Paginator
						page={page}
						setPage={setPage}
						lastPage={lastPage}
					/>
				</Row>
			</Container>

			<GlobalStandardDetailForm
				pageName={pageName}
				selected={selected}
				callbackAction={handleEdit}
			/>

			<Confirm
				modal={modal}
				setModal={setModal}
				callbackAction={handleDelete}
			/>
		</Fragment>
	);
};

export default translate(GlobalStandardDetail);
