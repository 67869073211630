import React from "react";
import { Spinner } from "reactstrap";

const Fetching = () => {
	return (
		<div className="fetching-data">
			<Spinner type="grow" />
		</div>
	);
};

export default Fetching;
