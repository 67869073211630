import React from "react";
import { translate } from "react-switch-lang";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { ReactComponent as EditIcon } from "../../../../assets/icons/editar.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/basura.svg";
import { dateToLocale, isValidPermission } from "../../../../services/utils";
import { Badge } from "reactstrap";

const CorrectiveActionTable = (props) => {
	const { editElement, deleteElement, data, permissions } = props;

	return (
		<div className="base-div">
			<Table className="base-table">
				<Thead>
					<Tr>
						<Th>Estado</Th>
						<Th>Folio</Th>
						<Th>Fecha</Th>
						<Th>Fuente</Th>
						<Th>Quien Detecta?</Th>
						<Th>Tipo</Th>
						<Th>Área Afectada</Th>
						<Th>Responsable Área</Th>
						<Th>Descripción</Th>
						<Th>Usuario</Th>
						<Th>Acciones</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data &&
						data.map((item, i) => {
							let colorStatus = "secondary";
							if (item.status) {
								if (item.status.id === 1) colorStatus = "secondary";
								if (item.status.id === 2) colorStatus = "warning";
								if (item.status.id === 3) colorStatus = "info";
								if (item.status.id === 4) colorStatus = "success";
							}

							return (
								<Tr key={i}>
									<Td>
										{item && item.status ? (
											<Badge color={colorStatus}>{item.status.description}</Badge>
										) : (
											<Badge color={colorStatus}>Ingresada</Badge>
										)}
									</Td>
									<Td>{item.folio ? item.folio : ""}</Td>
									<Td>{item.date_nonconformity ? dateToLocale(item.date_nonconformity) : ""}</Td>
									<Td>{item.origin ? item.origin : ""}</Td>
									<Td>{item.who_detects ? item.who_detects : ""}</Td>
									<Td>{item.type ? item.type : ""}</Td>
									<Td>{item.affected_area ? item.affected_area : ""}</Td>
									<Td>{item.manager_area ? item.manager_area : ""}</Td>
									<Td>
										{item.description ? (
											<div
												dangerouslySetInnerHTML={{
													__html: item.description,
												}}
											></div>
										) : (
											""
										)}
									</Td>
									<Td>{item.create_user ? item.create_user.label : ""}</Td>
									<Td>
										<div>
											{isValidPermission("edit", permissions) && (
												<span className="table-actions" onClick={() => editElement(item)}>
													<EditIcon className="icon-edit" />
												</span>
											)}
											{isValidPermission("delete", permissions) && item.status && Number(item.status.id) === 1 && (
												<span className="table-actions icon trash" onClick={() => deleteElement(item)}>
													<DeleteIcon className="icon-delete" />
												</span>
											)}
										</div>
									</Td>
								</Tr>
							);
						})}
				</Tbody>
			</Table>
		</div>
	);
};

export default translate(CorrectiveActionTable);
