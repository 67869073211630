import React, { useState, useRef } from "react";
import { Card, CardHeader, CardBody, Col, Label } from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import classes from "../../audit_program.module.scss";
import { ReactComponent as DownloadIcon } from "../../../../../assets/icons/download-file.svg";
import { ReactComponent as RemoveIcon } from "../../../../../assets/icons/remove.svg";

import api from "../../../../../services/api";

const SectionThree = ({ data, setData, setIsFetching }) => {
	const refFile = useRef(null);

	const [section, setSection] = useState(false);

	const changeObjective = (editor) => {
		const newData = { ...data, objective: editor.getData() };
		setData(newData);
	};

	const changeScope = (editor) => {
		const newData = { ...data, scope: editor.getData() };
		setData(newData);
	};

	const handleSaveDocument = async (e) => {
		const files = e.target.files;

		if (files && files.length !== 0) {
			let newDocuments = [...data.documents];

			for (var i = 0; i < files.length; i++) {
				// eslint-disable-next-line
				const base64_file = await new Promise((resolve, reject) => {
					if (files[i]) {
						const reader = new FileReader();
						reader.readAsDataURL(files[i]);
						reader.onload = () => resolve(reader.result);
						reader.onerror = (error) => reject(error);
					}
				});

				newDocuments.push({
					id: "",
					name: files[i].name,
					url: base64_file,
					size: files[i].size,
					type: files[i].name.split(".").pop(),
					entity: "audits_program",
					screen_id: 8,
					entity_id: data.id,
				});
			}

			setIsFetching(true);
			api.post("/client/save", { documents: newDocuments }).then(
				(response) => {
					setData({ ...data, documents: response.documents });
					setIsFetching(false);
				}
			);
		}
	};

	const handleDeleteDocument = (document) => {
		setIsFetching(true);
		api.post("/client/delete", { document }).then((response) => {
			let newDocuments = [...data.documents];
			newDocuments = newDocuments.filter((d) => d.id !== document.id);
			setData({ ...data, documents: newDocuments });
			setIsFetching(false);
		});
	};

	return (
		<Card>
			<CardHeader
				className={`${classes.cardHeader} ${section && classes.active}`}
				onClick={() => setSection(!section)}
			>
				SECCION 3: ANTECEDENTES AUDITORIA
			</CardHeader>
			{section && (
				<CardBody className={`${classes.cardBody}`}>
					<div className="form-row">
						<Col md="12 mb-3">
							<Label htmlFor="audit_schedule_id">Objetivo</Label>
							<CKEditor
								editor={ClassicEditor}
								data={
									data && data.objective ? data.objective : ""
								}
								onChange={(event, editor) => {
									changeObjective(editor);
								}}
							/>
						</Col>

						<Col md="12 mb-3">
							<Label htmlFor="audit_schedule_id">Alcance</Label>
							<CKEditor
								editor={ClassicEditor}
								data={data && data.scope ? data.scope : ""}
								onChange={(event, editor) => {
									changeScope(editor);
								}}
							/>
						</Col>
					</div>
					<hr />
					{data.id && (
						<>
							<h4 style={{ fontSize: "15px" }}>
								Documentos de referencia y Requisitos Normativos
							</h4>
							<div className="form-tab-detail">
								<div>
									<div
										className="upload-doc"
										onClick={(e) =>
											refFile.current &&
											refFile.current.click()
										}
									>
										Subir Documento
									</div>
									<input
										style={{ display: "none" }}
										type="file"
										multiple
										ref={refFile}
										onChange={(e) => handleSaveDocument(e)}
									/>
								</div>

								<div className="table-docs">
									{data &&
										data.documents &&
										data.documents.map((document, i) => {
											return (
												<div
													className="table-item"
													key={i}
												>
													<div className="index">
														{i + 1}
													</div>
													<div className="name">
														{document.name}
													</div>
													<div className="actions">
														<DownloadIcon
															className="file-icons"
															onClick={() =>
																window.open(
																	document.url,
																	"_blank",
																	"noopener,noreferrer"
																)
															}
														/>
														<RemoveIcon
															className="file-icons"
															onClick={() =>
																handleDeleteDocument(
																	document
																)
															}
														/>
													</div>
												</div>
											);
										})}
								</div>
							</div>
						</>
					)}
				</CardBody>
			)}
		</Card>
	);
};

export default SectionThree;
