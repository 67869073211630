import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const get = (get_url, params) => {
	const url = `${API_URL}${get_url}`;

	return axios
		.get(url, {
			params,
			headers: {
				Authorization: localStorage.getItem("token"),
			},
		})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

const post = (post_url, form) => {
	const url = `${API_URL}${post_url}`;

	return axios
		.post(url, form, {
			headers: {
				Authorization: localStorage.getItem("token"),
			},
		})
		.then((response) => {
			return response.data;
		});
};

const getExcel = (get_url, params) => {
	const url = `${API_URL}${get_url}`;

	return axios
		.get(url, {
			params,
			headers: {
				Authorization: localStorage.getItem("token"),
			},
			responseType: "blob",
		})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export default {
	get,
	post,
	getExcel,
};
