import React, { useRef } from "react";
import { translate } from "react-switch-lang";
import api from "../../../../services/api";

import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download-file.svg";
import { ReactComponent as RemoveIcon } from "../../../../assets/icons/remove.svg";

const FormDocuments = (props) => {
	const { data, setData, setIsFetching } = props;

	const refFile = useRef(null);

	const handleSaveDocument = async (e) => {
		const files = e.target.files;

		if (files && files.length !== 0) {
			let newDocuments = [...data.documents];

			for (var i = 0; i < files.length; i++) {
				// eslint-disable-next-line
				const base64_file = await new Promise((resolve, reject) => {
					if (files[i]) {
						const reader = new FileReader();
						reader.readAsDataURL(files[i]);
						reader.onload = () => resolve(reader.result);
						reader.onerror = (error) => reject(error);
					}
				});

				newDocuments.push({
					id: "",
					name: files[i].name,
					url: base64_file,
					size: files[i].size,
					type: files[i].name.split(".").pop(),
					entity: "corrective_action",
					screen_id: 16,
					entity_id: data.id,
				});
			}

			setIsFetching(true);
			api.post("/client/save", { documents: newDocuments }).then((response) => {
				setData({ ...data, documents: response.documents });
				setIsFetching(false);
			});
		}
	};

	const handleDeleteDocument = (document) => {
		setIsFetching(true);
		api.post("/client/delete", { document }).then((response) => {
			let newDocuments = [...data.documents];
			newDocuments = newDocuments.filter((d) => d.id !== document.id);
			setData({ ...data, documents: newDocuments });
			setIsFetching(false);
		});
	};

	return (
		<div className="form-tab-detail">
			<div>
				<div className="upload-doc" onClick={(e) => refFile.current && refFile.current.click()}>
					Subir Documento
				</div>
				<input style={{ display: "none" }} type="file" multiple ref={refFile} onChange={(e) => handleSaveDocument(e)} />
			</div>

			<div className="table-docs">
				{data &&
					data.documents &&
					data.documents.map((document, i) => {
						return (
							<div className="table-item" key={i}>
								<div className="index">{i + 1}</div>
								<div className="name">{document.name}</div>
								<div className="actions">
									<DownloadIcon className="file-icons" onClick={() => window.open(document.url, "_blank", "noopener,noreferrer")} />
									<RemoveIcon className="file-icons" onClick={() => handleDeleteDocument(document)} />
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default translate(FormDocuments);
