import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import _ from "lodash";

import classes from "./items.module.scss";

import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { ReactComponent as ConfirmIcon } from "../../assets/icons/confirm.svg";

const Items = ({ options, setOptions, items, setItems, basepath, creatable }) => {
	const handleNew = () => {
		let newItems = [...items];
		newItems.push({
			id: "",
			name: "",
			slug: "",
		});

		setItems(newItems);
	};

	const handleConfirm = (index) => {
		if (!items[index].id) {
			toast.error("Debes seleccionar un elemento para guardar");
		}
	};

	const handleEdit = (index) => {
		window.open(basepath + items[index].slug, "_blank", "noreferrer");
	};

	const handleDelete = (index) => {
		let newItems = [...items];
		newItems.splice(index, 1);
		setItems(newItems);
	};

	const handleCreate = (opt, i) => {
		let newOptions = [...options];
		newOptions.push({
			value: opt.value,
			label: opt.label,
		});
		setOptions(newOptions);

		let newitems = [...items];
		newitems[i].id = opt.value;
		newitems[i].name = opt.label;
		setItems(newitems);
	};

	return (
		<div>
			<div>
				<div className={classes.item_button} onClick={() => handleNew()}>
					Agregar
				</div>
			</div>

			<div className={classes.item_table}>
				{items &&
					items.map((item, i) => {
						return (
							<div key={i} className={classes.item_item}>
								<div className={classes.first}>{i + 1}</div>
								<div className={classes.second}>
									{item.id ? (
										item.name
									) : (
										<div style={{ width: "95%" }}>
											{creatable ? (
												<CreatableSelect
													value={item.item_id ? options.filter((c) => Number(c.value) === Number(item.item_id)) : ""}
													options={options ? options.filter((option) => !_.map(items, "id").includes(option.value)) : []}
													formatCreateLabel={(value) => {
														return `Crear ${value}`;
													}}
													placeholder={""}
													onChange={(opt, meta) => handleCreate(opt, i)}
												/>
											) : (
												<Select
													defaultValue={item.item_id ? options.filter((c) => Number(c.value) === Number(item.item_id)) : ""}
													placeholder={<div>Seleccione...</div>}
													options={options ? options.filter((option) => !_.map(items, "id").includes(option.value)) : []}
													onChange={(value) => {
														let newItems = [...items];
														newItems[i].id = value.value;
														newItems[i].name = value.label;
														newItems[i].slug = value.slug;
														setItems(newItems);
													}}
												/>
											)}
										</div>
									)}
								</div>
								<div className={classes.third}>
									{item.id ? (
										<>{basepath && <EditIcon className={classes.icon} onClick={() => handleEdit(i)} />}</>
									) : (
										<ConfirmIcon
											style={{
												width: "3em",
												height: "3em",
											}}
											className={classes.icon}
											onClick={() => handleConfirm(i)}
										/>
									)}
									<DeleteIcon className={classes.icon} onClick={() => handleDelete(i)} />
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default Items;
