import React from "react";
import { translate } from "react-switch-lang";
import { Col, Label, Input } from "reactstrap";
import Select from "react-select";

const FormFields = (props) => {
	const { t, data, setData, customers } = props;
	const customer_id = localStorage.getItem("customer_id");

	const handleCustomer = (value) => {
		setData({
			...data,
			customer_id: value.value,
		});
	};

	return (
		<div className="form-tab-detail">
			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="name">Nombre</Label>
					<Input
						className="form-control"
						name="name"
						type="text"
						value={data && data.name ? data.name : ""}
						onChange={(e) =>
							setData({
								...data,
								name: e.target.value,
							})
						}
					/>
				</Col>
				<Col md="6 mb-3">
					<Label htmlFor="description">Descripción</Label>
					<Input
						className="form-control"
						name="description"
						type="text"
						value={data && data.description ? data.description : ""}
						onChange={(e) =>
							setData({
								...data,
								description: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			{customer_id === 1 && (
				<div className="form-row">
					<Col md="6 mb-3">
						<Label htmlFor="customer_id">{t("Customer")}</Label>
						<Select
							defaultValue={
								data && data.customer_id
									? customers.filter(
											(c) =>
												Number(c.value) ===
												Number(data.customer_id)
									  )
									: ""
							}
							placeholder={<div>{t("Select")}...</div>}
							options={customers}
							onChange={(value) => handleCustomer(value)}
						/>
					</Col>
				</div>
			)}
		</div>
	);
};

export default translate(FormFields);
