import React, { useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

import FormFields from "./FormFields";

import classes from "../../corrective_action.module.scss";

const SectionOne = ({ data, setData, users, userID, setIsFetching }) => {
	const [section, setSection] = useState(data.checker_sign ? false : true);

	return (
		<Card>
			<CardHeader className={`${classes.cardHeader} ${section && classes.active}`} onClick={() => setSection(!section)}>
				SECCIÓN 1: FORMULARIO ACCIÓN CORRECTIVA
			</CardHeader>
			{section && (
				<CardBody className={`${classes.cardBody}`}>
					<FormFields data={data} setData={setData} users={users} userID={userID} setIsFetching={setIsFetching} />
				</CardBody>
			)}
		</Card>
	);
};

export default SectionOne;
