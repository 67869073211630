import React, { useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

import classes from "../../audit_program.module.scss";
import Items from "../../../../../components/items/Items";

const SectionTwo = ({ data, setData, auditors }) => {
	const [section, setSection] = useState(false);

	const handleEdit = (auditors) => {
		setData({ ...data, auditors: auditors });
	};

	return (
		<Card>
			<CardHeader className={`${classes.cardHeader} ${section && classes.active}`} onClick={() => setSection(!section)}>
				SECCION 2: ANTECEDENTES EQUIPO AUDITOR
			</CardHeader>
			{section && (
				<CardBody className={`${classes.cardBody}`}>
					<Items options={auditors} items={data.auditors} setItems={handleEdit} basepath="/auditors/" />
				</CardBody>
			)}
		</Card>
	);
};

export default SectionTwo;
