import React, { useState } from "react";
import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";

import classes from "../../audit_program.module.scss";

import { ReactComponent as AddIcon } from "../../../../../assets/icons/add.svg";
import ProgramItem from "./ProgramItem";
import Items from "components/items/Items";

const SectionFive = ({ data, setData, auditors }) => {
	const [allStaff, setAllStaff] = useState([]);
	const [section, setSection] = useState(false);

	const handleAdd = () => {
		let newDetails = [...data.details];
		newDetails.push({
			id: "",
			date_detail: "",
			time_detail: "",
			departament: "",
			auditor_id: "",
		});
		setData({ ...data, details: newDetails });
	};

	const handleStaff = (staffs) => {
		let newStaffs = [...staffs];
		setData({ ...data, staffs: newStaffs });
	};

	return (
		<Card>
			<CardHeader className={`${classes.cardHeader} ${section && classes.active}`} onClick={() => setSection(!section)}>
				SECCION 4: INFORME DE AUDITORIA
			</CardHeader>
			{section && (
				<>
					<CardBody className={classes.cardBody}>
						<>
							<h4 style={{ fontSize: "16px" }}>Personal Contactado</h4>
							<Items options={allStaff} setOptions={setAllStaff} items={data.staffs} setItems={handleStaff} creatable={true} />
						</>

						<hr />

						<div className={classes.header}>
							<div style={{ width: "24%" }}>Hallazgos</div>
							<div style={{ width: "24%" }}>Referencia Normativa</div>
							<div style={{ width: "24%" }}>Responsable Área</div>
							<div style={{ width: "24%" }}>Clasificación (NCMA-NCME)</div>
							<div style={{ width: "4%" }}></div>
						</div>
						{data &&
							data.details &&
							data.details.map((detail, j) => {
								return <ProgramItem key={j} index={j} data={data} setData={setData} detail={detail} auditors={auditors} />;
							})}
					</CardBody>
					<CardFooter className={classes.cardFooter}>
						<AddIcon className={classes.buttonAdd} onClick={() => handleAdd()} />
					</CardFooter>
				</>
			)}
		</Card>
	);
};

export default SectionFive;
