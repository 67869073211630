import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";

import { translate } from "react-switch-lang";
import { withRouter } from "react-router-dom";
import { Password, Username, RememberPassword, ForgotPassword } from "../../constant";
import "react-toastify/dist/ReactToastify.css";

import api from "../../services/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Fetching from "../../components/fetching/Fetching";

const Login = (props) => {
	let history = useHistory();

	const [togglePassword, setTogglePassword] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [remember, setRemember] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [name, setName] = useState(localStorage.getItem("full_name"));

	const token = localStorage.getItem("token");

	useEffect(() => {
		localStorage.setItem("full_name", name);
	}, [name, token]);

	const HideShowPassword = (tPassword) => {
		setTogglePassword(!tPassword);
	};

	const handleLogin = (e) => {
		e.preventDefault();
		e.stopPropagation();

		let valid = true;
		if (!username) {
			valid = false;
			toast.error(props.t("The username field is required"));
		}

		if (!password && valid) {
			valid = false;
			toast.error(props.t("The password field is required"));
		}

		if (valid) {
			const form = {
				username,
				password,
				remember,
			};

			setIsFetching(true);
			api.post("/auth/login", form).then((response) => {
				const { user } = response;
				setName(user.full_name);
				setIsFetching(false);

				localStorage.setItem("token", user.authorization);
				localStorage.setItem("full_name", user.full_name);
				localStorage.setItem("expiration_date", user.expiration_date);
				localStorage.setItem("customer", user.customer);
				localStorage.setItem("customer_id", user.customer_id);
				localStorage.setItem("screens", JSON.stringify(user.screens));

				window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
			});
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			handleLogin(e);
		}
	};

	if (token) {
		window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
	}

	return (
		<Container fluid={true}>
			<ToastContainer />
			<Row>
				<Col
					xl="7"
					className="b-center bg-size"
					style={{
						backgroundImage: `url(${require("../../assets/images/login/2.png")})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
						display: "block",
					}}
				>
					<img
						className="bg-img-cover bg-center"
						style={{ display: "none" }}
						src={require("../../assets/images/login/2.png")}
						alt="looginpage"
					/>
				</Col>
				<Col xl="5" className="p-0">
					<div className="login-card">
						<div>
							<div>
								<div className="logo text-left">
									<img className="img-fluid for-light" src={require("../../assets/images/logo/login.png")} alt="looginpage" />
									<img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark.png")} alt="looginpage" />
								</div>
							</div>

							{isFetching ? (
								<Fetching />
							) : (
								<div className="login-main login-tab">
									<Form className="theme-form">
										<h4>{props.t("Sign in")}</h4>
										<p>{props.t("Enter your email & password to login")}</p>
										<FormGroup>
											<Label className="col-form-label">{props.t(Username)}</Label>
											<Input
												value={username}
												onChange={(e) => setUsername(e.target.value)}
												className="form-control"
												type="text"
												required=""
												placeholder="demo@audithor.cl"
												onKeyDown={handleKeyDown}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">{props.t(Password)}</Label>
											<Input
												className="form-control"
												type={togglePassword ? "text" : "password"}
												name="login[password]"
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												required=""
												placeholder="*********"
												onKeyDown={handleKeyDown}
											/>
											<div className="show-hide" onClick={() => HideShowPassword(togglePassword)}>
												<span className={togglePassword ? "" : "show"}></span>
											</div>
										</FormGroup>
										<FormGroup className="mb-0">
											<div className="checkbox ml-3">
												<Input
													id="checkbox1"
													type="checkbox"
													value={remember}
													onChange={(e) => setRemember(e.target.checked)}
												/>
												<Label className="text-muted" for="checkbox1">
													{props.t(RememberPassword)}
												</Label>
											</div>
											<div className="link" style={{ cursor: "pointer" }} onClick={() => history.push("/forgot_password")}>
												{props.t(ForgotPassword)}
											</div>

											<Button color="primary" className="btn-block" onClick={(e) => handleLogin(e)}>
												{props.t("Sign in")}
											</Button>
										</FormGroup>
									</Form>
								</div>
							)}
						</div>
					</div>
				</Col>
			</Row>
			<ToastContainer />
		</Container>
	);
};

export default withRouter(translate(Login));
