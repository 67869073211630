import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";

import App from "./App";

const Root = () => {
	return <App></App>;
};
ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
