import React, { useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from "reactstrap";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";

import Fetching from "../../../../components/fetching/Fetching";
import FormFields from "./FormFields";
import FormPermissions from "./FormPermissions";
import FormRoles from "./FormRoles";

import api from "../../../../services/api";

const baseObject = {
	name: "",
	email: "",
	username: "",
	active: "",
	address: "",
	password: "",
	confirm_password: "",
	customer_id: "",
	country_id: "",
	commune_id: "",
	permissions: [],
	user_roles: [],
};

const UserForm = (props) => {
	const { pageName, t, countries, allCommunes, customers, callbackAction } =
		props;

	let history = useHistory();
	const { user_id } = useParams();

	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState({ ...baseObject });
	const [selectTab, setSelectTab] = useState("1");

	const handleClose = () => {
		history.push("/admin/users");
	};

	const validSave = () => {
		if (!data.name || data.name === "") {
			toast.error(props.t("The name field is required"));
			return false;
		}
		if (!data.username || data.username === "") {
			toast.error(props.t("The username field is required"));
			return false;
		}

		if (data.password || data.confirm_password) {
			if (!data.password || data.password === "") {
				toast.error(props.t("The password field is required"));
				return false;
			}
			if (data.password !== data.confirm_password) {
				toast.error(props.t("Passwords entered do not match"));
				return false;
			}
		}

		return true;
	};

	const handleSave = async () => {
		if (validSave()) {
			setIsFetching(true);
			const response = await callbackAction(data);
			if (response) {
				setData({ ...baseObject });
			}
			setIsFetching(false);
		}
	};

	useEffect(() => {
		setIsFetching(false);
		if (user_id) {
			setIsFetching(true);
			api.get("/admin/user", { selected: user_id }).then((response) => {
				setData(response.user);
				setIsFetching(false);
			});
		} else {
			setData({ ...baseObject });
		}
		// eslint-disable-next-line
	}, [user_id, allCommunes]);

	return (
		<Modal
			isOpen={user_id ? true : false}
			toggle={handleClose}
			size="lg"
			backdrop="static"
		>
			{isFetching ? (
				<Fetching />
			) : (
				<>
					<ModalHeader toggle={handleClose}>
						{t(pageName)}
					</ModalHeader>
					<ModalBody>
						<Nav tabs>
							<NavItem>
								<NavLink
									className={
										selectTab === "1" ? "active" : ""
									}
									onClick={() => setSelectTab("1")}
								>
									{t(`Form`)}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={
										selectTab === "2" ? "active" : ""
									}
									onClick={() => setSelectTab("2")}
								>
									{t(`Roles`)}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={
										selectTab === "3" ? "active" : ""
									}
									onClick={() => setSelectTab("3")}
								>
									{t(`Permissions`)}
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={selectTab}>
							<TabPane className="fade show" tabId="1">
								<FormFields
									data={data}
									setData={setData}
									countries={countries}
									allCommunes={allCommunes}
									customers={customers}
								/>
							</TabPane>
							<TabPane tabId="2">
								<FormRoles data={data} setData={setData} />
							</TabPane>
							<TabPane tabId="3">
								<FormPermissions
									data={data}
									setData={setData}
								/>
							</TabPane>
						</TabContent>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={handleClose}>
							{t("Cancel")}
						</Button>
						<Button color="primary" onClick={() => handleSave()}>
							{t("Save")}
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};

export default translate(UserForm);
