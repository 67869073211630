import React from "react";
import { translate } from "react-switch-lang";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

const Confirm = (props) => {
	const { modal, setModal, t, callbackAction } = props;

	const toggle = () => setModal(!modal);

	const applyAction = () => {
		callbackAction();
		setModal(false);
	};

	return (
		<Modal isOpen={modal} toggle={toggle}>
			<ModalHeader toggle={toggle} style={{ fontSize: "1rem" }}>
				{t("Are you sure you want to perform this action?")}
			</ModalHeader>
			<ModalFooter>
				<Button color="secondary" onClick={toggle}>
					{t("Cancel")}
				</Button>
				<Button color="primary" onClick={applyAction}>
					{t("Accept")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default translate(Confirm);
