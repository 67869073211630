import React from "react";
import { Col, Label, Input } from "reactstrap";
import Select from "react-select";

import classes from "./customerForm.module.scss";

const FormFields = ({ t, data, setData, countries, communes, allCommunes, handleCountry, handleCommune }) => {
	return (
		<>
			<div className="form-row" style={{ marginTop: "10px" }}>
				<Col md="6 mb-3">
					<Label htmlFor="name">{t("Name")}</Label>
					<Input
						className="form-control"
						name="name"
						type="text"
						value={data && data.name}
						onChange={(e) =>
							setData({
								...data,
								name: e.target.value,
							})
						}
					/>
				</Col>
				<Col md="6 mb-3">
					<Label htmlFor="email">{t("Email")}</Label>
					<Input
						className="form-control"
						name="email"
						type="text"
						value={data && data.email}
						onChange={(e) =>
							setData({
								...data,
								email: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="phone">{t("Phone")}</Label>
					<Input
						className="form-control"
						name="phone"
						type="text"
						value={data && data.phone}
						onChange={(e) =>
							setData({
								...data,
								phone: e.target.value,
							})
						}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="number">{t("Mobile")}</Label>
					<Input
						className="form-control"
						name="number"
						type="text"
						value={data && data.number}
						onChange={(e) =>
							setData({
								...data,
								number: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="country_id">{t("Country")}</Label>

					<Select
						defaultValue={data && data.country_id ? countries.filter((c) => Number(c.value) === Number(data.country_id)) : ""}
						placeholder={<div>{t("Select")}...</div>}
						options={countries}
						onChange={(value) => handleCountry(value)}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="commune_id">{t("Commune")}</Label>
					<Select
						defaultValue={data && data.commune_id ? allCommunes.filter((c) => Number(c.value) === Number(data.commune_id)) : ""}
						placeholder={<div>{t("Select")}...</div>}
						options={communes}
						onChange={(value) => handleCommune(value)}
					/>
				</Col>
			</div>

			<div className="form-row">
				<Col md="12 mb-3">
					<Label htmlFor="address">{t("Address")}</Label>
					<Input
						className="form-control"
						name="address"
						type="text"
						value={data && data.address}
						onChange={(e) =>
							setData({
								...data,
								address: e.target.value,
							})
						}
					/>
				</Col>
			</div>

			{data && data.id && (
				<div className="form-row">
					<Col md="12 mb-6" className={classes.divButtons} onClick={() => setData({ ...data, active: !data.active })}>
						{data.active && Number(data.active) === 1 ? (
							<div className={classes.buttonActive}>{t("Active")}</div>
						) : (
							<div className={classes.buttonInactive}>{t("Inactive")}</div>
						)}
					</Col>
				</div>
			)}
		</>
	);
};

export default FormFields;
