import React, { useEffect, useState, Fragment } from "react";
import { translate } from "react-switch-lang";
import { useHistory, useParams } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { toast } from "react-toastify";

import Fetching from "../../../../components/fetching/Fetching";
import Breadcrumb from "../../../../layout/breadcrumb";
import SectionOne from "./SectionOne/SectionOne";
import SectionTwo from "./SectionTwo/SectionTwo";
import SectionThree from "./SectionThree/SectionThree";
import SectionFour from "./SectionFour/SectionFour";
import SectionFive from "./SectionFive/SectionFive";

import api from "../../../../services/api";

const baseObject = {
	id: "",
	name: "",
	slug: "",
	cargo: "",
	section: "",
	area: "",
	objective: "",
	scope: "",
	audit_program_status_id: "",
	audit_schedule_id: "",
	user_id: "",
	customer_id: "",
	deleted_at: "",
	standard_details: [],
	items: [],
	details: [],
	auditors: [],
	staffs: [],
};

const AuditProgramForm = () => {
	let history = useHistory();

	const { audit_program_id } = useParams();

	let pageName = "";
	let prevOne = "";
	if (window.location.pathname.includes("/audit_program/")) {
		pageName = audit_program_id ? "Editar Programa de Auditoría" : "Nuevo Programa de Auditoría";
		prevOne = "Programa De Auditoría";
	}
	if (window.location.pathname.includes("/audit_report/")) {
		pageName = audit_program_id ? "Editar Informe de Auditoría" : "Nuevo Informe de Auditoría";
		prevOne = "Informe De Auditoría";
	}

	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState({ ...baseObject });
	const [auditsSchedule, setAuditsSchedule] = useState([]);
	const [roles, setRoles] = useState([]);
	const [auditors, setAuditors] = useState([]);
	const [user, setUser] = useState({});

	useEffect(() => {
		setIsFetching(true);
		api.get("/client/audits_program/detail", { audit_program_id }).then((response) => {
			if (response.audit_program) {
				setData(response.audit_program);
			}
			setUser(response.user);
			setAuditsSchedule(response.audits_schedule);
			setAuditors(response.auditors);
			setRoles(response.roles);
			setIsFetching(false);
		});
	}, [audit_program_id]);

	const validSave = () => {
		if (data && data.auditors && data.auditors.length === 0) {
			toast.error("Debe ingresar al menos un auditor en el equipo para guardar.");
			return false;
		}
		if (data && data.details && data.details.length === 0) {
			toast.error("Debe ingresar al menos un programa de auditoria para guardar.");
			return false;
		}

		let isValid = true;
		data.details.forEach((detail) => {
			if (window.location.pathname.includes("/audit_program/")) {
				if (!detail.date_detail) {
					toast.error("Debe ingresar una fecha para cada detalle del programa guardar.");
					isValid = false;
				}

				if (isValid && !detail.time_detail) {
					toast.error("Debe ingresar una hora para cada detalle del programa guardar.");
					isValid = false;
				}

				if (isValid && !detail.departament) {
					toast.error("Debe ingresar una departamento / función para cada detalle del programa guardar.");
					isValid = false;
				}

				if (isValid && !detail.auditor_id) {
					toast.error("Debe seleccionar un auditor para cada detalle del programa guardar.");
					isValid = false;
				}
			}
		});

		if (!isValid) {
			return false;
		}

		return true;
	};

	const handleSave = () => {
		if (validSave()) {
			setIsFetching(true);
			api.post("/client/audits_program/save", { data, path: window.location.pathname })
				.then((response) => {
					setIsFetching(false);

					if (window.location.pathname.includes("/audit_program/")) {
						toast.success("Programa guardado correctamente.");
						history.push("/audit_program");
					}
					if (window.location.pathname.includes("/audit_report/")) {
						toast.success("Informe guardado correctamente.");
						history.push("/audit_report");
					}
				})
				.catch((error) => {
					setIsFetching(false);
				});
		}
	};

	const handleConfirm = () => {
		setIsFetching(true);
		api.post("/client/audits_program/confirm", { id: data.id })
			.then((response) => {
				setIsFetching(false);
				if (window.location.pathname.includes("/audit_program/")) {
					toast.success("Programa de Auditoria confirmada correctamente.");
				}
				if (window.location.pathname.includes("/audit_report/")) {
					toast.success("Informe de Auditoria confirmada correctamente.");
				}
				setData({ ...data, confirmed: true });
			})
			.catch((e) => {
				setIsFetching(false);
			});
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<Fragment>
			<Breadcrumb title={pageName} prev_one={prevOne} parent="Audithor" />

			<Container fluid={true}>
				{data.id && !data.confirmed && (
					<div style={{ padding: "5px 5px" }}>
						<Button color="success" style={{ background: "red !important" }} onClick={() => handleConfirm()}>
							Confirmar
						</Button>
					</div>
				)}

				<SectionOne data={data} setData={setData} auditsSchedule={auditsSchedule} roles={roles} user={user} />
				<SectionTwo data={data} setData={setData} auditors={auditors} />
				<SectionThree data={data} setData={setData} setIsFetching={setIsFetching} />
				{window.location.pathname.includes("/audit_program/") && <SectionFour data={data} setData={setData} auditors={auditors} />}
				{window.location.pathname.includes("/audit_report/") && <SectionFive data={data} setData={setData} auditors={auditors} />}

				<div style={{ display: "flex", justifyContent: "center", marginBottom: "3em" }}>
					<Button
						color="secondary"
						style={{ marginRight: "10px" }}
						onClick={() =>
							window.location.pathname.includes("/audit_program/") ? history.push("/audit_program") : history.push("/audit_report")
						}
					>
						Cancelar
					</Button>
					{!data.confirmed && (
						<Button color="primary" onClick={() => handleSave()}>
							Guardar
						</Button>
					)}
				</div>
			</Container>
		</Fragment>
	);
};

export default translate(AuditProgramForm);
