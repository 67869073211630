import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import _ from "lodash";

import Breadcrumb from "../../../layout/breadcrumb";
import Search from "../../../components/list/Search";

import { ReactComponent as NoDataIcon } from "../../../assets/icons/no-data.svg";

import api from "../../../services/api";
import Fetching from "../../../components/fetching/Fetching";
import CorrectiveActionTable from "./table/CorrectiveActionTable";
import Paginator from "../../../components/list/Paginator";
import Confirm from "../../../components/confirm/Confirm";

const CorrectiveAction = ({ t }) => {
	let history = useHistory();

	const pageName = "Acciones Correctivas";
	const [isFetching, setIsFetching] = useState(true);
	const [search, setSearch] = useState("");
	const [mainSearch, setMainSearch] = useState("");
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [permissions, setPermissions] = useState([]);
	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");
	const [total, setTotal] = useState("");
	const [lastPage, setLastPage] = useState("");

	const [selected, setSelected] = useState("");
	const [modal, setModal] = useState(false);

	useEffect(() => {
		const form = {
			page,
			search,
			path: window.location.pathname,
		};

		setIsFetching(true);
		api.get("/client/corrective_actions", form).then((response) => {
			updateData(response);
		});
		// eslint-disable-next-line
	}, [page, search]);

	const updateData = (response) => {
		const { corrective_actions, permissions } = response;

		setPermissions(permissions);
		setData(corrective_actions.data);
		setFrom(corrective_actions.from);
		setTo(corrective_actions.to);
		setTotal(corrective_actions.total);
		setLastPage(corrective_actions.last_page);

		setIsFetching(false);
	};

	const delayedQuery = useCallback(
		_.debounce((v) => {
			setSearch(v);
		}, 500),
		[]
	);

	const setDelaySearch = (value) => {
		setMainSearch(value);
		delayedQuery(value);
	};

	const createElement = () => {
		history.push("/corrective_actions/create");
	};

	const editElement = (item) => {
		history.push("/corrective_actions/" + item.slug);
	};

	const deleteElement = (item) => {
		setSelected(item.id);
		setModal(true);
	};

	const handleDelete = () => {
		setIsFetching(true);
		api.post("/client/corrective_actions/delete", { selected, page, search }).then((response) => {
			updateData(response);
			setSelected("");

			toast.success(t("Record deleted successfully"));
		});
	};

	if (isFetching) {
		return <Fetching />;
	}

	return (
		<Fragment>
			<Breadcrumb title={pageName} parent="Maintainers" />

			<Container fluid={true}>
				<Row>
					<Search
						search={mainSearch}
						setSearch={setDelaySearch}
						to={to}
						from={from}
						total={total}
						permissions={permissions}
						handleCreate={createElement}
					/>

					{data.length === 0 ? (
						<div className="no-data">
							<div className="no-data-text">No existen datos</div>
							<NoDataIcon className="no-data-icon" />
						</div>
					) : (
						<>
							<CorrectiveActionTable editElement={editElement} deleteElement={deleteElement} data={data} permissions={permissions} />
							<Paginator page={page} setPage={setPage} lastPage={lastPage} />
						</>
					)}
				</Row>
			</Container>

			<Confirm modal={modal} setModal={setModal} callbackAction={handleDelete} />
		</Fragment>
	);
};

export default translate(CorrectiveAction);
