import React, { useState, useEffect } from "react";
import { translate } from "react-switch-lang";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import LayoutApp from "./components/app";
import store, { saveState } from "./store";
import { routes } from "./route";
import ConfigDB from "./data/customizer/config";

import interceptor from "./services/api.interceptor";
import { isValidToken } from "./services/utils";

import Login from "./page/authentication/Login";
import Error404 from "./page/error/Error404";

const App = (props) => {
	interceptor.setupInterceptors(store, props);

	const [anim, setAnim] = useState("");
	const token = isValidToken() ? localStorage.getItem("token") : "";

	const animation =
		localStorage.getItem("animation") ||
		ConfigDB.data.router_animation ||
		"fade";
	const abortController = new AbortController();

	useEffect(() => {
		setAnim(animation);
		window.addEventListener("unload", saveState);

		return function cleanup() {
			abortController.abort();
		};
	}, [animation, abortController, token]);

	if (!token) {
		localStorage.removeItem("token");
		localStorage.removeItem("full_name");
		localStorage.removeItem("expiration_date");
		localStorage.removeItem("customer");
		localStorage.removeItem("customer_id");
		localStorage.removeItem("screens");
	}

	return (
		<Provider store={store}>
			<BrowserRouter basename={`/`}>
				<Switch>
					<Route
						path={`${process.env.PUBLIC_URL}/login`}
						component={Login}
					></Route>

					<Route
						path={`${process.env.PUBLIC_URL}/forgot_password`}
						component={Login}
					></Route>

					<LayoutApp>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/`}
							render={() => {
								return (
									<Redirect
										to={`${process.env.PUBLIC_URL}/dashboard`}
									/>
								);
							}}
						/>
						<TransitionGroup>
							<Switch>
								{routes.map(({ path, Component }) => {
									return (
										<Route
											key={path}
											exact
											path={`${process.env.PUBLIC_URL}${path}`}
										>
											{({ match }) => (
												<CSSTransition
													in={match != null}
													timeout={100}
													classNames={anim}
													unmountOnExit
												>
													<div>
														{token ? (
															<Component />
														) : (
															<Redirect
																to={{
																	pathname:
																		"/login",
																	state: {
																		from: props.location,
																	},
																}}
															/>
														)}
													</div>
												</CSSTransition>
											)}
										</Route>
									);
								})}
								<Route component={Error404} />
							</Switch>
						</TransitionGroup>
					</LayoutApp>
				</Switch>
			</BrowserRouter>
		</Provider>
	);
};

export default translate(App);
