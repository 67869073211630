import React from "react";
import Select from "react-select";
import { Col, Label, Input } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { translate } from "react-switch-lang";

const Fields = ({ data, setData, standards }) => {
	const changeDescription = (editor) => {
		const newData = { ...data, description: editor.getData() };
		setData(newData);
	};

	return (
		<div className="form-tab-detail">
			<div className="form-row">
				<Col md="6 mb-3">
					<Label htmlFor="standard_id">Norma</Label>
					<Select
						defaultValue={data.standard_id ? standards.filter((c) => Number(c.value) === Number(data.standard_id)) : ""}
						placeholder={<div>Seleccione...</div>}
						options={standards}
						onChange={(value) => {
							setData({
								...data,
								standard_id: value.value,
							});
						}}
						isDisabled={data.id ? true : false}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="code">Código</Label>
					<Input
						className="form-control"
						name="code"
						type="text"
						value={data && data.code && data.code ? data.code : ""}
						onChange={(e) =>
							setData({
								...data,
								code: e.target.value,
							})
						}
						readOnly={data.id ? true : false}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="audit_schedule_id">Descripción</Label>
					<CKEditor
						editor={ClassicEditor}
						data={data && data.description ? data.description : ""}
						onChange={(event, editor) => {
							changeDescription(editor);
						}}
					/>
				</Col>

				<Col md="6 mb-3">
					<Label htmlFor="audit_schedule_id">Fecha Revisión</Label>
					<Input
						className="form-control"
						name="name"
						type="date"
						value={data && data.verification_date ? data.verification_date : ""}
						onChange={(e) =>
							setData({
								...data,
								verification_date: e.target.value,
							})
						}
					/>
				</Col>
			</div>
		</div>
	);
};

export default translate(Fields);
