import React from "react";
import Select from "react-select";
import { Table, Thead, Tr, Th, Tbody, Td } from "react-super-responsive-table";
import { Input } from "reactstrap";

import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

import classes from "../../corrective_action.module.scss";

const Items = ({ data, setData, users }) => {
	const handleDelete = (index) => {
		let newItems = [...data.corrective_actions_details];
		newItems.splice(index, 1);
		setData({ ...data, corrective_actions_details: newItems });
	};

	const handleNew = () => {
		let newItems = [...data.corrective_actions_details];
		newItems.push({
			id: "",
			description: "",
			responsible_id: "",
			start_date: "",
			end_date: "",
		});

		setData({ ...data, corrective_actions_details: newItems });
	};

	return (
		<div>
			{!data.closer_sign && (
				<div>
					<div className={classes.item_button} onClick={() => handleNew()}>
						Agregar
					</div>
				</div>
			)}

			<Table>
				<Thead>
					<Tr>
						<Th style={{ width: "40%" }}>Descripción de las acciones</Th>
						<Th style={{ width: "20%" }}>Responsable implementarlas</Th>
						<Th style={{ width: "15%" }}>Fecha Inicio</Th>
						<Th style={{ width: "15%" }}>Fecha Término</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{data &&
						data.corrective_actions_details &&
						data.corrective_actions_details.map((item, index) => {
							return (
								<Tr key={index}>
									<Td>
										<Input
											className="form-control"
											name="description"
											type="text"
											value={item && item.description && item.description ? item.description : ""}
											onChange={(e) => {
												let newItems = [...data.corrective_actions_details];
												newItems[index].description = e.target.value;
												setData({ ...data, corrective_actions_details: newItems });
											}}
											readOnly={data.closer_sign ? true : false}
										/>
									</Td>
									<Td>
										<Select
											defaultValue={
												item.responsible_id ? users.filter((c) => String(c.value) === String(item.responsible_id)) : ""
											}
											placeholder={<div>Seleccione...</div>}
											options={users}
											onChange={(value) => {
												let newItems = [...data.corrective_actions_details];
												newItems[index].responsible_id = value.value;
												setData({ ...data, corrective_actions_details: newItems });
											}}
											isDisabled={data.closer_sign ? true : false}
										/>
									</Td>
									<Td>
										<Input
											className="form-control"
											name="start_date"
											type="date"
											value={item && item.start_date ? item.start_date : ""}
											onChange={(e) => {
												let newItems = [...data.corrective_actions_details];
												newItems[index].start_date = e.target.value;
												setData({ ...data, corrective_actions_details: newItems });
											}}
											readOnly={data.closer_sign ? true : false}
										/>
									</Td>
									<Td>
										<Input
											className="form-control"
											name="end_date"
											type="date"
											value={item && item.end_date ? item.end_date : ""}
											onChange={(e) => {
												let newItems = [...data.corrective_actions_details];
												newItems[index].end_date = e.target.value;
												setData({ ...data, corrective_actions_details: newItems });
											}}
											readOnly={data.closer_sign ? true : false}
										/>
									</Td>
									<Td>{!data.closer_sign && <DeleteIcon className={classes.icon} onClick={() => handleDelete(index)} />}</Td>
								</Tr>
							);
						})}
				</Tbody>
			</Table>
		</div>
	);
};

export default Items;
