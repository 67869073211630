import React, { Fragment } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

const Sample = (props) => {
	return (
		<Fragment>
			<Breadcrumb title="Dashboard" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader style={{ padding: "15px" }}>
								<h5>Audithor</h5>
								<span>Bienvenidos a la plataforma web</span>
							</CardHeader>
							<CardBody>
								<Row>
									<Col sm="3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
										<img className="img-fluid for-dark" src={require("../../assets/images/logo/logo.png")} alt="" />
									</Col>
									<Col sm="9">
										<p>
											Un software de servicios creado para la realización de auditorias de PRIMERA y/o SENGUNDA PARTE, el
											objetivo principal es entregar una HERRAMIENTA DE GESTIÓN para realizar SEGUIMIENTO, MEDICIÓN, ANALISIS Y
											EVALUACIÓN de los sistemas de gestión de CALIDAD, INOCUIDAD, SEGURIDAD Y SALUD OCUPACIONAL, MEDIO AMBIENTE
											Y CUALQUIER NORMA DE GESTIÓN, de esta forma podran implementar de forma correcta todas las actividades
											para la MEJORA CONTINUA.
										</p>

										<p>
											Como funciona, las empresas pueden cargar sus propios CHECK LIST o solicitar la creación de CHECK LIST a
											la medida, según la norma a auditar.
										</p>

										<p>
											AUDITHOR cuenta con permisos para ingresar, listado de auditores, programa de auditoria, mail de
											información (alertas), calendario de auditorias, check list. informes, formato acciones correctivas (no
											conformidades).
										</p>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Sample;
