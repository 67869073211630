import React from "react";
import { translate } from "react-switch-lang";
import { Input, Table, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

import classes from "../standard.module.scss";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/basura.svg";
import { integerValidate, isNumeric } from "../../../../services/utils";
import FormStandardDetailQuestions from "./FormStandardDetailQuestions";

const NoOptionsMessage = (props) => {
	return (
		<components.NoOptionsMessage {...props}>
			<span className="custom-css-class">Sin opciones</span>
		</components.NoOptionsMessage>
	);
};

const FormStandardDetail = (props) => {
	const { t, data, setData, globalStandardsDetail, setGlobalStandardsDetail, questions } = props;

	const handleAddDetail = () => {
		let newDetails = [...data.details];
		newDetails.push({
			id: "",
			global_standard_detail_id: "",
			description: "",
			indice: "",
			document: "",
			show_questions: false,
			questions: [],
		});

		setData({ ...data, details: newDetails });
	};

	const handleStandardDetail = (i, opt, meta) => {
		let value = "";
		let newDetails = [...data.details];

		if (opt) {
			value = opt.value;

			const existOption = globalStandardsDetail.find((gs) => gs.value === value);

			if (!existOption) {
				let newGlobalStandardsDetail = [...globalStandardsDetail];
				newGlobalStandardsDetail.push({ value: value, label: value });
				setGlobalStandardsDetail(newGlobalStandardsDetail);
			}
		}

		newDetails[i].global_standard_detail_id = value;

		setData({ ...data, details: newDetails });
	};

	const handleDescription = (i, value) => {
		let newDetails = [...data.details];
		newDetails[i].description = value;
		setData({ ...data, details: newDetails });
	};

	const handleIndice = (i, value) => {
		let newDetails = [...data.details];
		newDetails[i].indice = value;
		setData({ ...data, details: newDetails });
	};

	const handleDeleteDetail = (i) => {
		let newDetails = [...data.details];
		newDetails.splice(i, 1);

		setData({ ...data, details: newDetails });
	};

	const handleCloseQuestion = () => {
		let newDetails = [...data.details];
		newDetails.forEach((detail, j) => {
			newDetails[j].show_questions = false;
		});

		setData({ ...data, details: newDetails });
	};

	return (
		<div className="form-tab-detail">
			<div>
				<Button className={classes.buttonAdd} color="primary" onClick={() => handleAddDetail()}>
					{t("Add new detail")}
				</Button>
			</div>

			<Table>
				<thead>
					<tr>
						<td style={{ width: "40%" }}>Detalle Norma</td>
						<td>Descripción</td>
						<td style={{ width: "10%" }}>Orden</td>
						<td></td>
						<td></td>
					</tr>
				</thead>
				<tbody>
					{data &&
						data.details &&
						data.details.map((detail, i) => {
							return (
								<tr key={i}>
									<td>
										<CreatableSelect
											value={
												detail.global_standard_detail_id &&
												globalStandardsDetail.filter(
													(g) =>
														g.value ===
														(isNumeric(detail.global_standard_detail_id)
															? Number(detail.global_standard_detail_id)
															: detail.global_standard_detail_id)
												)
											}
											options={globalStandardsDetail}
											components={{ NoOptionsMessage }}
											formatCreateLabel={(value) => {
												return `Crear ${value}`;
											}}
											placeholder={<div>{t("Select")}...</div>}
											isClearable
											onChange={(opt, meta) => {
												handleStandardDetail(i, opt, meta);
											}}
										/>
									</td>
									<td>
										<Input
											className="form-control"
											type="text"
											placeholder={""}
											value={detail && detail.description}
											onChange={(e) => handleDescription(i, e.target.value)}
										/>
									</td>
									<td>
										<Input
											className="form-control"
											type="text"
											placeholder={""}
											value={detail && detail.indice}
											onChange={(e) => handleIndice(i, e.target.value)}
											onKeyDown={integerValidate}
										/>
									</td>
									<td>
										{detail.id && (
											<>
												<div
													className={classes.show_questions}
													onClick={() => {
														let newDetails = [...data.details];
														newDetails[i].show_questions = !detail.show_questions;
														setData({ ...data, details: newDetails });
													}}
												>
													Ver Preguntas
												</div>
												<Modal isOpen={detail.show_questions} toggle={handleCloseQuestion} backdrop="static">
													<ModalHeader toggle={handleCloseQuestion}>Preguntas</ModalHeader>
													<ModalBody>
														<FormStandardDetailQuestions
															baseQuestions={questions}
															data={data}
															setData={setData}
															index={i}
														/>
													</ModalBody>
												</Modal>
											</>
										)}
									</td>
									<td>
										<DeleteIcon className={classes.buttonDelete} onClick={() => handleDeleteDetail(i)} />
									</td>
								</tr>
							);
						})}
				</tbody>
			</Table>
		</div>
	);
};

export default translate(FormStandardDetail);
