import React, { Fragment } from "react";
import { translate } from "react-switch-lang";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Home } from "react-feather";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as BackIcon } from "../../assets/icons/volver.svg";

const Breadcrumbs = (props) => {
	let history = useHistory();

	return (
		<Fragment>
			<Container fluid={true}>
				<div className="page-title">
					<Row>
						<Col xs="6">
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginRight: "10px",
									cursor: "pointer",
								}}
								onClick={() => history.goBack()}
							>
								<BackIcon />
							</div>
							<h3>{props.t(props.title)}</h3>
						</Col>
						<Col xs="6">
							<Breadcrumb>
								<BreadcrumbItem>
									<Link to={`${process.env.PUBLIC_URL}/`}>
										<Home />
									</Link>
								</BreadcrumbItem>
								{props.parent && (
									<BreadcrumbItem>
										{props.t(props.parent)}
									</BreadcrumbItem>
								)}
								{props.prev_one && (
									<BreadcrumbItem>
										{props.t(props.prev_one)}
									</BreadcrumbItem>
								)}
								<BreadcrumbItem active>
									{props.t(props.title)}
								</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};

export default translate(Breadcrumbs);
